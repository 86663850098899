import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { NbSidebarService, NbMenuService } from '@nebular/theme';
import { LayoutService } from '../../services/layout.service';
import { SharedService } from '../../../shared/service/shared.service';
import { AppComponent } from '../../../app.component';
import { CmpDetail, UserInfo } from './CmpModel'

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})


export class HeaderComponent implements OnInit {

  // user: any = {
  //   'name': 'RecTrans',
  //   'picture': 'src\assets\images\nick.png',
  // };

  user: UserInfo = new UserInfo();


  cmpDetail = new CmpDetail();
  branch: string;
  curYear: string;
  UsName: string;


  userMenu = [
    { title: 'Profile', data: { id: 'Profile' } },
    { title: 'Change Branch', data: { id: 'updateBranch' } },
    { title: 'Log out', data: { id: 'logout' } },
  ];

  // branchMenu = [
  //   { title: 'Update Branch', data: { id: 'updateBranch' } },
  // ];

  constructor(
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private menuService: NbMenuService,
    private router: Router,
    private sharedService: SharedService,
    private app: AppComponent,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // this.userService.getUsers()
    //   .subscribe((users: any) => this.user = users.nick);

    this.route.queryParams.subscribe(params => {
      this.user.name = params["name"];
      this.user.picture = 'assets/images/Logogreen.png';

    });

    this.UsName = this.user.name;

    this.getCompanyDetail()
    this.branch = localStorage.getItem('tpsBranch');
    this.menuEvents();

  }


  getCompanyDetail() {
    this.sharedService.getCmpInfo().subscribe((res) => {
      if (res == null) return;
      if (res['data'] == null) return;
      this.cmpDetail.cmpName = res['data']['CmpName'];
      var sDt = res['data']['CmpInstallDt'];

      if (sDt != null) {
        let dt1: Date;
        dt1 = new Date(sDt);
        dt1 = this.sharedService.utcDate(dt1);
        //var Year = dt1.getFullYear() + "-" + Number(dt1.getFullYear()) + 1;
        // 14-06-2024
        //this.curYear = dt1.getFullYear() + "-" + (Number(dt1.getFullYear()) + 1);
        this.curYear = "2024-25";
      }



    }, (err) => { });
  }


  menuEvents() {
    this.menuService.onItemClick()
      .subscribe((item) => {
        if (item && item.item && item.item.data) {
          if (item.item.data.id === 'logout') {
            this.logout();
          } else if (item.item.data.id === 'updateBranch') {
            this.router.navigate(['/branch'], { queryParams: this.user });
          }
        }
      });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }
  logout() {
    this.sharedService.logout();
  }


}
