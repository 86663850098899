import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class DataService {

  private token = new BehaviorSubject(null);
  currentToken = this.token.asObservable();

  constructor() { }



  setToken(token) {
    this.token.next(token);
  }

}
