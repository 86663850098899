import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { InvDtl, LrDtl, LrAcm, InvTcDtl } from './InvModel';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient) { }

  getCmpLh() {
    return this.http.get(environment.HOST_URL + AppConfig.GetCmpLh);
  }

  // pdfNewInvoice(sObj) {
  //   var url = environment.HOST_URL + AppConfig.MakePdfInvoice;
  //   const httpOptions = {
  //     responseType: 'blob' as 'json'
  //   };
  //   return this.http.post(url, sObj);
  // }


  pdfInvoice(sObj) {
    var url = environment.HOST_URL + AppConfig.pdfFreightInvoice;
    const httpParam = new HttpParams();
    let parameters: any = {
      params: httpParam,
    }
    parameters.responseType = 'application/json';
    // const httpOptions = {
    //   responseType: 'blob' as 'json'
    // };
    //return this.http.get(url, parameters);

    return this.http.post<[any]>(url, sObj, parameters);

  }

  mailInvoice(sObj) {
    var url = environment.HOST_URL + AppConfig.pdfFreightInvoice;
    const httpParam = new HttpParams();
    let parameters: any = {
      params: httpParam,
    }
    parameters.responseType = 'application/json';
    // const httpOptions = {
    //   responseType: 'blob' as 'json'
    // };
    return this.http.post(url, sObj, parameters);
  }

  GetTCForFront() {
    return this.http.get(environment.HOST_URL + AppConfig.GetTCForFront);
  }
  downloadDocumentLink(documentName: string, url: string) {
    const link = document.createElement('a');
    link.href = url;
    documentName = "_" + new Date().getTime() + ".pdf"
    link.download = documentName;
    link.click();
    link.remove();
  }

  getServerPath() {
    return this.http.get(environment.HOST_URL + AppConfig.getServerPath.concat('/?id=Photo1'));
  }


  getBranch() {
    return this.http.get<[]>(environment.HOST_URL + AppConfig.BranchList);
  }
  getAcName() {
    return this.http.get<LrAcm[]>(environment.HOST_URL + AppConfig.getAcmListForInv);
    //GetAcmListForCombo
  }

  getAcmListForCombo(sType) {
    return this.http.get<[any]>(environment.HOST_URL + AppConfig.getAcmListForCombo.concat('/?id=').concat(sType));
  }


  getInvoiceByInvNo(sObj) {
    return this.http.post<InvDtl>(environment.HOST_URL + AppConfig.getInvByInvNo, sObj);
  }

  getInvoiceByCode(sId) {
    return this.http.get<InvDtl>(environment.HOST_URL + AppConfig.getInvByCode.concat('/?id=').concat(sId));
  }

  getLrListByInvCode(sObj) {
    return this.http.post<LrDtl[]>(environment.HOST_URL + AppConfig.getLrListByInvCode, sObj);
  }


  // getLrListByBpNameZZ(sObj) {
  //   return this.http.post<LrDtl[]>(environment.HOST_URL + AppConfig.getLrListByBpNameZZ, sObj);
  // }

  createInvoice(sObj: InvDtl) {
    const httpParam = new HttpParams().append('branch', localStorage.getItem('tpsBranch'));
    return this.http.post<InvDtl>(environment.HOST_URL + AppConfig.saveInv, sObj, { params: httpParam });
  }

  updateInvoice(params: InvDtl) {
    return this.http.post<InvDtl>(environment.HOST_URL + AppConfig.updateInv, params);
  }

  deleteInvoice(sUnPUnkCode) {
    return this.http.get(environment.HOST_URL + AppConfig.deleteInv + "/?id=".concat(sUnPUnkCode));
  }

  getInvSummary(sObj, isExportToExcel: boolean = false) {
    var sUrl = environment.HOST_URL + AppConfig.getInvSummary;

    const httpParam = new HttpParams();
    let parameters: any = {
      params: httpParam
    }
    if (isExportToExcel) {
      parameters.responseType = 'blob' as 'json'
    }
    return this.http.post<[any]>(sUrl, sObj, parameters);

  }

  getInvGstRegister(sObj, isExportToExcel: boolean = false) {
    var sUrl = environment.HOST_URL + AppConfig.getInvGstSummary;

    const httpParam = new HttpParams();
    let parameters: any = {
      params: httpParam
    }
    if (isExportToExcel) {
      parameters.responseType = 'blob' as 'json'
    }
    return this.http.post<[any]>(sUrl, sObj, parameters);

  }

  saveInvTc(sObj: InvTcDtl) {
    return this.http.post<InvTcDtl>(environment.HOST_URL + AppConfig.saveInvTc, sObj);
  }

  updateInvTc(params: InvTcDtl) {
    return this.http.post<InvTcDtl>(environment.HOST_URL + AppConfig.updateInvTc, params);
  }

  getInvTcList() {
    return this.http.get<[]>(environment.HOST_URL + AppConfig.getInvTcList);
  }

  deleteInvTc(sTcPUnkCode) {
    return this.http.get(environment.HOST_URL + AppConfig.deleteInvTc + "/?id=".concat(sTcPUnkCode));
  }

  getInvTc(sTcPUnkCode) {
    return this.http.get(environment.HOST_URL + AppConfig.getInvTc + "/?id=".concat(sTcPUnkCode));
  }


}
