import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { VwDtlStd, VwColStd, AcAdd, RepDtlStd, RepColStd } from '../shared.model';
import { AppConfig } from '../../app.config';
import { VrTypeDtl } from '../shared.model'
import { VhCatDtl } from '../../lr-mod/LrModel';
import { BranchDtl } from '../../masters/masterModel';
import { UserMenu } from '../../@theme/layouts/layoutModel';



@Injectable({
    providedIn: 'root',
})

export class SharedService {

    constructor(
        private router: Router,
        private http: HttpClient
    ) { }


    downloadExcelReportLink(documentName: string, url: string) {
        const link = document.createElement('a');
        link.href = url;
        link.download = documentName;
        link.click();
        link.remove();
    }


    getAcAddress(id) {
        return this.http.get<AcAdd>(environment.HOST_URL + AppConfig.getAcAdd + '/?id='.concat(id));
    }



    getBranch() {

        return this.http.get<BranchDtl>(environment.HOST_URL + AppConfig.BranchList);


    }
    gGetVrType(sType) {
        var url = environment.HOST_URL + AppConfig.getVrTypeList.concat('/?type=').concat(sType);
        return this.http.get<VrTypeDtl>(url);
    }

    gGetVhCatList() {
        var url = environment.HOST_URL + AppConfig.getVhCatList;
        return this.http.get<VhCatDtl>(url);
    }

    getAcmListForCombo(sType) {
        var url = environment.HOST_URL + AppConfig.getAcmListForCombo.concat('/?id=').concat(sType);
        return this.http.get<[any]>(url);
    }

    getViewsByGrp(sId) {
        return this.http.get<VwDtlStd[]>(environment.HOST_URL + AppConfig.getViewsByGrp.concat('/?Id=').concat(sId));
    }

    getVwColsByGrp(sId) {
        return this.http.get<VwColStd[]>(environment.HOST_URL + AppConfig.getVwColsByGrp.concat('/?Id=').concat(sId));
    }

    getRepDtl(sId) {
        return this.http.get<RepDtlStd[]>(environment.HOST_URL + AppConfig.getRepsList.concat('/?Id=').concat(sId));
    }

    //GetColsByGrp

    getRepCol(sId) {
        return this.http.get<RepColStd[]>(environment.HOST_URL + AppConfig.getRepColList.concat('/?Id=').concat(sId));
    }

    clone(obj) {
        if (null == obj || "object" != typeof obj) return obj;
        var copy = obj.constructor();
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
    }


    getCmpInfo() {
        // Recursive-4
        // get Company Information from CmpInfo Table
        var sUrl = environment.HOST_URL + AppConfig.getCmpInfo;
        return this.http.get<any>(sUrl);
    }


    checkLogin() {
        if (localStorage.getItem('tpsToken') !== null) {
            this.router.navigate(['/user']);
        } else {
            this.logout();
        }
    }

    isLoggedIn() {
        const token = localStorage.getItem('tpsToken');
        if (token != null) {
            return true;
        }
        this.logout();
        return false;
    }

    logout() {
        localStorage.removeItem('tpsToken');
        localStorage.removeItem('tpsBranch');

        localStorage.removeItem('tpsChlnFilter');
        localStorage.removeItem('tpsLhcFilter');

        localStorage.removeItem('tpsLrFilter');
        localStorage.removeItem('tpsDvFilter');

        localStorage.removeItem('tpsBlFilter');
        localStorage.removeItem('tpsFvFilter');

        localStorage.removeItem('tpsTrFilter');
        localStorage.removeItem('tpsUldFilter');

        localStorage.removeItem('tpsMrFilter');

        localStorage.removeItem('trpincFilter');
        //

        this.router.navigate(['/auth']);

    }

    StringToDate(value: any): Date | null {
        if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
            const str = value.split('/');

            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);

            return new Date(year, month, date);
        } else if ((typeof value === 'string') && value === '') {
            return new Date();
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }


    gGetUsShowLrFrght() {
        var token = localStorage.getItem('tpsToken');
        var decode = jwt_decode(token);
        var sUserMode = decode.A5;
        return sUserMode.substring(6, 7);
    }

    AllowedMe(sTrMode: string, sFrmMode: string): boolean {
        var token = localStorage.getItem('tpsToken');
        var decode = jwt_decode(token);
        var sUserMode = decode.A5;
        if (sFrmMode == "Trn" && sTrMode == "Add" && decode.A5 == "Operator") {
            return false;
        }

        if (sFrmMode == "Mas") {
            if (sTrMode == "Add") {
                if (sUserMode.substring(0, 1) == "Y")
                    return true;
                else
                    return false;
            }
            else if (sTrMode == "Edit") {
                if (sUserMode.substring(1, 2) == "Y")
                    return true;
                else
                    return false;
            }
            else if (sTrMode == "Del") {
                if (sUserMode.substring(2, 3) == "Y")
                    return true;
                else
                    return false;
            }
        }
        else if (sFrmMode == "Trn") {
            if (sTrMode == "Add") {
                if (sUserMode.substring(3, 4) == "Y")
                    return true;
                else
                    return false;
            }
            else if (sTrMode == "Edit") {
                if (sUserMode.substring(4, 5) == "Y")
                    return true;
                else
                    return false;
            }
            else if (sTrMode == "Del") {
                if (sUserMode.substring(5, 6) == "Y")
                    return true;
                else
                    return false;
            }
        }
        else
            return false;
    }

    gAAdmin() {
        var token = localStorage.getItem('tpsToken');
        var decode = jwt_decode(token);
        var UserType = decode.A3;


        return UserType;

        // if (UserType == "AAdmin")
        //     return 1;
        // else if (UserType == "Admin" || UserType == "SAdmin")
        //     return 2
        // else
        //     return 3;

    }

    gGetUserType() {
        var token = localStorage.getItem('tpsToken');
        var decode = jwt_decode(token);
        return decode.A3;
    }

    gGetCmpType() {
        var token = localStorage.getItem('tpsToken');
        var decode = jwt_decode(token);
        return decode.A7;
    }


    utcDate(date: Date) {
        if (date == null) {
            return null;
        }
        return new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
                date.getMilliseconds()
            ));
    }
    GetMenuList() {
        var url = environment.HOST_URL + AppConfig.GetMenuList;
        return this.http.get<UserMenu>(url);
    }

    SaveMenuDtl(sObj) {
        var url = environment.HOST_URL + AppConfig.SaveMenuDtl;
        return this.http.post(url, sObj);
    }


}
