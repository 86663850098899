import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { VhDtl, DocDtl, DocFilter } from '../vehicle/VhModel'
import { AppConfig } from '../app.config';


@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(private http: HttpClient) { }

  getVhList() {
    return this.http.get<VhDtl[]>(environment.HOST_URL + AppConfig.getVehList);
  }

  getVhDetailById(sVhNo) {
    var url = environment.HOST_URL + AppConfig.getVehicleById.concat('/?id=').concat(sVhNo);
    return this.http.get<VhDtl>(url);
  }

  createVh(params: VhDtl) {
    var url = environment.HOST_URL + AppConfig.addVeh;
    return this.http.post<VhDtl>(url, params);
  }

  updateVh(params: VhDtl) {
    return this.http.post<VhDtl>(environment.HOST_URL + AppConfig.updateVeh, params);
  }

  deleteVh(sVhPRefNo) {
    return this.http.get(environment.HOST_URL + AppConfig.deleteVeh + '/?id='.concat(sVhPRefNo));
  }
  GetDocByCmpCodeGrpName(grpName) {
    var url = environment.HOST_URL + AppConfig.GetDocList.concat('/?grpName=').concat(grpName);
    return this.http.get<DocDtl>(url);
  }
  SaveDoc(params: DocFilter) {
    var url = environment.HOST_URL + AppConfig.SaveDoc;
    return this.http.post<DocFilter>(url, params);
  }
  GetViewDoc(obj) {
    //var url = environment.HOST_URL + AppConfig.GetViewDoc.concat('/?ScGrpName=').concat(grpName + '&&ScDocName=' + DocName);
    var url = environment.HOST_URL + AppConfig.GetViewDoc;
    const httpParam = new HttpParams();
    let parameters: any = {
      params: httpParam
    }
    parameters.responseType = 'text'
    return this.http.post<DocDtl>(url, obj, parameters);
  }
  DeleteDoc(params: DocFilter) {
    var url = environment.HOST_URL + AppConfig.DeleteDoc;
    return this.http.post<DocFilter>(url, params);
  }
}
