import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { VehicleService } from '../vehicle.service';
import { VhDtl } from '../VhModel';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/service/shared.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare const CrctVhNo: any;

@Component({
  selector: 'ngx-vh-form',
  templateUrl: './vh-form.component.html',
  styleUrls: ['./vh-form.component.scss']
})
export class VhFormComponent implements OnInit, OnDestroy {

  title: string;
  fCanSave: boolean = true;
  scManager = new Subscription();
  VhData: VhDtl = new VhDtl();

  @Input() eVhNo;
  @Input() spinner: boolean;
  toasterTitle = 'Vehicle';

  constructor(private sVhService: VehicleService,
    private sRouter: Router, private sSharedService: SharedService,
    private toasterService: NbToastrService, private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close();
  }

  NewVeh() {
    this.VhData = new VhDtl();
    this.title = "Add New Vehicle";
    this.fCanSave = true;
  }

  getVhDtlByName() {
    this.scManager.add(this.sVhService.getVhDetailById(this.eVhNo).subscribe(res => {
      if (res['data']) this.VhData = res['data'];
      this.fCanSave = this.sSharedService.AllowedMe("Edit", "Mas");
    }, (err) => { }));
  }

  CrctMe() {
    var vhno = CrctVhNo(this.VhData.VhNo);
    if (vhno == "" && this.VhData.VhNo != "")
      this.toasterService.info("Invalid Vehicle No.", this.toasterTitle);
    else
      this.VhData.VhNo = vhno;
  }

  saveVeh() {

    var newvh = CrctVhNo(this.VhData.VhNo);

    if (this.VhData.VhNo == undefined) {
      this.toasterService.danger("Can't Save  Vehicle No. should not be Blank....", this.toasterTitle);
      return;
    }

    if (newvh == "") {
      this.toasterService.danger("Can't Save  Invalid Vehicle No. ", this.toasterTitle);
      return;
    }

    this.VhData.VhNo = newvh

    if (this.VhData.VhPRefNo == 0) this.replVeh();
    else if (this.VhData.VhPRefNo > 0) this.updateVeh();
  }

  replVeh() {
    this.spinner = true;
    this.scManager.add(this.sVhService.createVh(this.VhData).subscribe(res => {
      this.spinner = false;
      if (res['StatusCode'] == 501) this.toasterService.danger('Duplicate Entry...', this.toasterTitle);
      else {
        this.VhData = res['data'];
        this.toasterService.success('Created successfully', this.toasterTitle);
        this.fCanSave = false;
        this.activeModal.close(res['data']);
      }


    }, (err) => {
      this.spinner = false;
    }));

  }

  updateVeh() {
    this.spinner = true;
    this.scManager.add(this.sVhService.updateVh(this.VhData).subscribe(res => {
      this.spinner = false;
      if (res['StatusCode'] == 501) this.toasterService.danger('Duplicate Entry...', this.toasterTitle);
      else {
        this.toasterService.success('Updated successfully', this.toasterTitle);
        this.activeModal.close(res['data']);
      }
    }, (err) => {
      this.spinner = false;
    }));
  }

  ngOnDestroy() {
    if (this.scManager) this.scManager.unsubscribe();
  }

}
