export class BranchDtl {
    constructor(
        public BrPRefNo: number = 0,
        public BrName: string = '',
        public BrAbbr: string = '',
        public BrCsAcRefNo: number = 0,
        public BrCsOpBal: number = 0,
        public BrCsCrDr: string = '',
        public BrAdd1: string = '',
        public BrAdd2: string = '',
        public BrPhNo: string = '',
    ) { }
}

export class StationDtl {
    constructor(
        public StationPRefNo: Number = 0,
        public Station: string = '',
        public State: string = ''
    ) { }
}

export class MatDtl {
    constructor(
        public MatPRefNo: number = 0,
        public MatName: string = '',
        public MatRate: number = 0,
        public MatHsn: string = '',
    ) { }
}

export class PkTypeDtl {
    constructor(
        public PkgPRefNo: number = 0,
        public PkgType: string = '',
        public OldPkgType: string = '',
        public PkgUnkCode: string = '',
    ) { }
}

export class DocDtl {
    constructor(
        public DocPRefNo: number = 0,
        public DocName: string = '',
        public DocGrpName: string = '',
    ) { }
}

export class UnitDtl {
    constructor(
        public UnitPRefNo: number = 0,
        public UnitName: string = '',
    ) { }
}