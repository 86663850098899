import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';
import { UnitDtl } from '../masterModel';
import { MastersService } from '../masters.service';
import { NbToastrService } from '@nebular/theme';


@Component({
  selector: 'ngx-unit-create',
  templateUrl: './unit-create.component.html',
  styles: []
})
export class UnitCreateComponent implements OnInit {

  modalHeader: string;
  spinner: boolean;
  subscriptionManager = new Subscription();

  sObj: UnitDtl = new UnitDtl();
  OldName: string;
  MatData = [];

  constructor(
    private activeModal: NgbActiveModal,
    private sMasterService: MastersService,
    private sToastrService: NbToastrService
  ) { }

  ngOnInit() {
    if (this.sObj.UnitPRefNo) {
      this.OldName = this.sObj.UnitName;
    }
  }

  closeModal() {
    this.activeModal.close();
  }

  saveUnitData() {

    if (this.sObj.UnitName != "") {
      if (this.sObj.UnitPRefNo) {
        {
          this.updateUnitObj();
        }
      } else {
        this.addUnitObj();
      }
    }
  }

  addUnitObj() {
    this.spinner = true;
    this.subscriptionManager.add(this.sMasterService.addUnit(this.sObj).subscribe((res) => {
      if (res['StatusCode'] == 501) {
        this.sToastrService.danger('Duplicate Unit', 'Unit Details');
        this.activeModal.close(null);
      }
      else {

        this.activeModal.close(res['data']);
        this.spinner = false;
      }
    }, (err) => {
      this.spinner = false;
    }));
  }

  updateUnitObj() {
    this.spinner = true;
    this.subscriptionManager.add(this.sMasterService.updateUnit(this.sObj).subscribe((res) => {
      if (res['StatusCode'] == 501) {
        this.sObj.UnitName = this.OldName;
        this.sToastrService.danger('Duplicate Unit', 'Unit Details');
        this.activeModal.close(null);
      }
      else {
        this.activeModal.close(res['data']);
      }
      this.spinner = false;
    }, (err) => {
      this.spinner = false;
    }));
  }

  ngOnDestroy() {
    if (this.subscriptionManager) {
      this.subscriptionManager.unsubscribe();
    }
  }

}
