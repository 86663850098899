import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { SharedService } from './shared/service/shared.service';
import { CommonService } from './shared/service/common.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    NgbModule.forRoot(),
    ThemeModule.forRoot(),
    SharedModule

  ],
  bootstrap: [AppComponent],
  providers: [
    SharedService, CommonService,
    { provide: APP_BASE_HREF, useValue: '/' },
  ],


})

export class AppModule {
}
