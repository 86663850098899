export const ERRORCODES = {
    1003: 'Invalid Client Id',
    5000: 'Password can not update',
    5001: 'Uniq id is missing',
    5002: 'Invalid password',
    5003: 'User not found',
    5004: 'Name or email is already exist',
    5005: 'Name is already exist',

    601: 'Account Used in LR',
    602: 'Account Used in Challan',
    603: 'Account Used in Invoice',
    604: 'Account Used in Voucher',
    605: 'Account Used in MR',
    606: 'Account Used in LHC',
};
