import { AgPndBl } from "../voucher-gen/gen-vou-model";

export class Acm {
    constructor(
        public AcAtRefNo: number = 0,
        public AcPRefNo: number = 0,
        public AcPUnkCode: string = '',
        public AcName: string = '',
        public AcType: string = '',
        public GrpFRefNo: number = 0,
        public AcPtType: string = '',
        public AcOpBal: number = 0,
        public AcOpCrDr: string = '',
        public AcGSTNo: string = '',
        public AcPANNo: string = '',
        public AcMobNo: string = '',
        public AcEmlNo: string = '',
        public AcShrtName: string = '',
        public AcImpFlag: string = '',
        public AcTdsPer: number = 0,
        public AcCommPer: number = 0,

        public AcAdd1: string = '',
        public AcAdd2: string = '',
        public AcArea: string = '',
        public AcCity: string = '',
        public AcState: string = '',
        public AcStateCode: number = 0,
        public AcIGstFlag: string = '',
        public AcActive: string = '',
        public AcBankStat: string = '',
        public AcVhIncFld: string = '',
        public AcBkNo: string = '',
        public AcBkType: string = '',
        public AcBkIFSC: string = '',
        public AcBkName: string = '',
        public AcCanDel: string = '',
        public AcPhNo: string = '',
        public AcPinCode: string = '',
        public invList?: AgPndBl[],
    ) { }
}

export class AcGrp {
    constructor(
        public GrpAtRefNo: number = 0,
        public GrpPRefNo: number = 0,
        public GrpName: string = '',
        public GrpInName: string = '',
        public Ungrp: string = '',
        public GrpCd: string = '',
        public UnGrpCd: string = '',
        public GrpType: string = '',
        public AcmStat: string = '',
        public DspStat: string = '',
        public PrefNo: number = 0,
        public GrpCrDr: string = '',
        public AddStat: string = '',
        public BankStat: string = '',
        public GrpCd6: string = '',
        public GrpName6: string = '',
        public GrpCd13: string = '',
        public GrpName13: string = '',
        public GrpCd20: string = '',
        public GrpName20: string = '',
        public GrpCd27: string = '',
        public GrpName27: string = '',
    ) { }
}

export class AcGrpName {
    constructor(
        public GrpName: string = '',
        public BankStat: string = ''
    ) { }
}

export class StateDtl {
    constructor(
        public State: string = '',
        public StateCode: number = 0
    ) { }
}
export class FindAcm {
    constructor(
        public AcName: string = '',
        public AcPRefNo: Number = 0,
        public AcPUnkCode: string = ''
    ) { }
}

export class AcmFilter {
    constructor(
        public AcType: string = '',
        public AcPtType: string = '',
        public AcName: string = ''
    ) { }
}