import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { CanActivateChild } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService {

    constructor(
        private sharedService: SharedService,
    ) { }

    canActivate(): boolean {
        if (this.sharedService.isLoggedIn()) {
            return true;
        } else {
            this.sharedService.logout();
            return false;
        }
    }

}


@Injectable({
    providedIn: 'root',
})
export class AuthGuardChildService implements CanActivateChild {

    constructor(
        private sharedService: SharedService,
    ) { }

    canActivateChild(): boolean {
        if (this.sharedService.isLoggedIn()) {
            return true;
        } else {
            this.sharedService.logout();
            return false;
        }
    }
}
