import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'ngx-err-msg',
    template: `<div class="text-danger" *ngIf="errorMessage !== null">{{errorMessage}}</div>`,
})

export class ErrMsgComponent implements OnInit {

    @Input() control: FormControl;
    @Input() fieldName: FormControl;

    constructor() { }

    get errorMessage() {
        for (const propertyName in this.control.errors) {
            if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
                return this.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
            }
        }
        return null;
    }

    getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
        const config = {
            'required': this.fieldName + ' is required',
            'appPhoneValidate': this.fieldName + ' is invalid',
            'appEmailValidate': this.fieldName + ' is invalid',
            'appPasswordValidate': this.fieldName + ' must containt 8 characters, capital letters, ' +
                'lowercase, numbers and special character.',
            'minlength': `minnimum length ${validatorValue.requiredLength}`,
            'min': `minumum value ${validatorValue.min}`,
            'max': `maximum value ${validatorValue.max}`,
            'matchPassword': this.fieldName + ' is mismatched',
            'appEqualvalidate': this.fieldName + ' is mismatched',
            'appWebValidate': this.fieldName + ' is invalid',
        };

        return config[validatorName];
    }

    ngOnInit() {
    }

}
