import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input() dropdownList = [];
  @Output() dropdown = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  selectData(data) {
    this.dropdown.emit(data);
  }

}
