import { Acm } from "../ac-mod/AcmModel";
import { FirmDtl } from "../user-management/userModel";


export class EmailInv {
    constructor(
        public InvEmail: boolean = false,
        public LrDtls?: LrDtl[],
        public FirmData?: FirmDtl,
        public InvData?: InvDtl,

    ) { }
}


export class InvDtl {
    constructor(
        public InvPRefNo: number = 0,
        public InvMode: string = '',
        public InvPUnkCode: string = '',
        public InvType: string = 'BL',
        public InvBrName: string = '',
        public InvNo: number = 0,
        public InvDt: Date = new Date(),
        public InvExtn: string = '',
        public InvBpRefNo: number = 0,
        public InvBpName: string = '',
        public LrTotFrAmt: number = 0,
        public InvDvAmt: number = 0,
        public InvTotFrAmt: number = 0,
        public InvGstAmt: number = 0,
        public InvGstPer: number = 0,
        public InvSGstAmt: number = 0,
        public InvCGstAmt: number = 0,
        public InvIGstAmt: number = 0,
        public InvNetFrAmt: number = 0,
        public InvROAmt: number = 0,
        public InvAdvAmt: number = 0,
        public InvToPayAmt: number = 0,
        public InvNetBalAmt: number = 0,
        public InvRemark: string = '',
        public InvSubDt?: Date,
        public InvTcPRefNo: number = 0,

        public LrDtls?: LrDtl[],
        public acm?: Acm,

    ) { }
}

export class LrAcm {
    constructor(
        public AcPRefNo: number = 0,
        public AcName: string = '',
        public AcIGstFlag: string = 'S'
    ) { }
}

export class LrDtl {
    constructor(
        public LrPUnkCode: string = '',
        public LrPRefNo: number = 0,
        public LrNo: number = 0,
        public LrCtFrom: string = '',
        public LrCtTo: string = '',
        public LrCrName: string = '',
        public LrCnName: string = '',
        public LrPkgNo: number = 0,
        public LrMatName: string = '',
        public LtQty: number = 0,
        public ChlnQty: number = 0,
        public LrGrsAmt: number = 0,
        public LrGstAmt: number = 0,
        public LrNetFrAmt: number = 0,
        public LrAdvAmt: number = 0,
        public LrToPayAmt: number = 0,
        public LrNetBalAmt: number = 0,
        public IsSelected: boolean = false,


    ) { }
}

export class FindInv {
    constructor(
        public InvBrName: string = '',
        public InvNo: number = 0,
        public InvExtn: string = ''
    ) { }
}

export class InvLrFilter {
    constructor(
        public InvPUnkCode: string = '',
        public InvBpRefNo: number = 0,
        public DtFrom?: Date,
        public DtTo?: Date,
    ) { }
}

export class InvFilter {
    constructor(
        public GrpFlag: string = '',
        public MenuFlag: string = '',
        public InvType: string = 'BL',
        public NoFrom: number = 0,
        public NoTo: number = 0,
        public DtFrom?: Date,
        public DtTo?: Date,
        public InvBrName: string = '',
        public InvExtn: string = '',
        public InvBpName: string = '',
        public InvMonth: string = '',
        public PtRefNo: number = 0,
        public IsExportExcel: boolean = false,
    ) { }
}
export class PreVwInv {
    constructor(
        public InvFrmtName: string = '',
    ) { }
}
export class AcAdd {
    constructor(
        public AcPRefNo: number = 0,
        public AcAdd1: string = '',
        public AcAdd2: string = '',
        public AcAArea: string = '',
        public AcCity: string = '',
        public AcState: string = '',
        public AcGstNo: string = '',
        public AcPhNo: string = '',
        public AcEmlNo: string = ''

    ) { }
}

export class InvPrint {
    constructor(
        public InvPUnkCode: string = '',
        public InvIsEmail: boolean = false,
        public InvPrintOn: string = '',
        public InvPrintFormat: string = '',
        public RepPRefNo: number = 0
    ) { }
}

export class InvTcDtl {
    constructor(
        public TcAtRefNo: number = 0,
        public TcPRefNo: number = 0,
        public TcPUnkCode: string = '',
        public TcName: string = '',
        public Tc1: string = '',
        public Tc2: string = '',
        public Tc3: string = '',
        public Tc4: string = '',
        public Tc5: string = '',
        public Tc6: string = '',
        public Tc7: string = '',
        public Tc8: string = '',
        public Tc9: string = '',
        public Tc10: string = '',
    ) { }
}