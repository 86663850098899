import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { DahboardCounter, FrmShort } from '../shared.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { OptionInv } from '../../user-management/userModel';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private http: HttpClient
  ) { }

  getMaterial() {
    return this.http.get<[]>(environment.HOST_URL + AppConfig.getMatList);
  }

  getUnit() {
    return this.http.get<[]>(environment.HOST_URL + AppConfig.getUnitList);
  }

  getCityList() {
    return this.http.get<[]>(environment.HOST_URL + AppConfig.getStationList);
  }

  getDrvList() {
    return this.http.get<[]>(environment.HOST_URL + AppConfig.getDrvList);
  }

  getVehicle() {
    return this.http.get<[]>(environment.HOST_URL + AppConfig.getVehList);
  }

  getState() {
    return this.http.get<[]>(environment.HOST_URL + AppConfig.getStateList);
  }

  getAcType() {
    return this.http.get<[]>(environment.HOST_URL + AppConfig.getAcTypeList);
  }

  getVrType() {
    return this.http.get<[]>(environment.HOST_URL + AppConfig.getVrTypeList);
  }

  getDashboardCounterdata(sObj) {
    return this.http.post<[DahboardCounter]>(environment.HOST_URL + AppConfig.getDashboardCount, sObj);
  }

  getOptionInv() {
    return this.http.get<OptionInv>(environment.HOST_URL + AppConfig.getOptionInv);
  }
}
