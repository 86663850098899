import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AcGrp, Acm } from './AcmModel';
import { AppConfig } from '../app.config';
import { AgPndBl } from '../voucher-gen/gen-vou-model';
import { AddressDtl } from '../trad-invoice/trad-invoice-model';

@Injectable({
  providedIn: 'root'
})

export class AcmService {

  constructor(private http: HttpClient) { }

  getAcmList(sObj) {
    return this.http.post<Acm[]>(environment.HOST_URL + AppConfig.getAcmList, sObj);
  }



  getAcName(sType) {
    var url = environment.HOST_URL + AppConfig.getAcmListForCombo.concat('/?type=').concat(sType);
    return this.http.get<any>(url);
  }


  getAcmDetailById(sNo) {
    var url = environment.HOST_URL + AppConfig.getAcmById.concat('/?id=').concat(sNo);
    return this.http.get<Acm>(url);
  }

  getAcmPndBill(sNo) {
    var url = environment.HOST_URL + AppConfig.getAcmPndBill.concat('/?id=').concat(sNo);
    return this.http.get<AgPndBl[]>(url);
  }
  createAcm(params: Acm) {
    return this.http.post<Acm>(environment.HOST_URL + AppConfig.addAcm, params);
  }

  createAcmPromise(params: Acm) {
    return this.http.post<Acm>(environment.HOST_URL + AppConfig.addAcm, params).toPromise();
  }

  updateAcm(params: Acm) {
    return this.http.post<Acm>(environment.HOST_URL + AppConfig.updateAcm, params);
  }

  deleteAcm(ss) {
    return this.http.get(environment.HOST_URL + AppConfig.deleteAcm + '/?id='.concat(ss));
  }

  getStateData() {
    return this.http.get<[]>(environment.HOST_URL + AppConfig.getStateList);
  }

  getAcType() {
    return this.http.get<[]>(environment.HOST_URL + AppConfig.getAcTypeList);
  }

  createAcGrp(params: AcGrp) {
    return this.http.post<Acm>(environment.HOST_URL + AppConfig.saveAcGrp, params);
  }

  updateAcGrp(params: AcGrp) {
    return this.http.post<Acm>(environment.HOST_URL + AppConfig.updateAcGrp, params);
  }


  saveAddressDtl(params: AddressDtl) {
    return this.http.post<AddressDtl>(environment.HOST_URL + AppConfig.saveAddressdtl, params);
  }

  updateAddressDtl(params: AddressDtl) {
    return this.http.post<AddressDtl>(environment.HOST_URL + AppConfig.updateAddressdtl, params);
  }

  deleteAddressDtl(ss) {
    return this.http.get(environment.HOST_URL + AppConfig.deleteAddressdtl + '/?id='.concat(ss));
  }

  getAddressById(ss) {
    return this.http.get(environment.HOST_URL + AppConfig.getAddressById + '/?id='.concat(ss));
  }

  getAddressList() {
    return this.http.get<AddressDtl[]>(environment.HOST_URL + AppConfig.getAddressList);
  }


}
