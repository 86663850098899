export class CmpDetail
{
  constructor(
    public cmpName:string='',
    public cmpYear:string=''
  ){}

}
export class UserInfo
{
  constructor(
    public name: string='',
    public picture: string=''
      )  {}
}
