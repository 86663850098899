import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { Branch, UserBrDtl } from '../../../master-management/master-management.model';
import { BranchService } from './branch.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../../app.component';
import { UserInfo } from '../../../@theme/components/header/CmpModel'
import { UsBrDtl } from '../../../user-management/userModel';



@Component({
  selector: 'ngx-branch-selection',
  templateUrl: './branch-selection.component.html',
})

export class BranchSelectionComponent implements OnInit, OnDestroy {

  subscriptionManager = new Subscription();
  spinner: boolean;

  branchDropdownList: UserBrDtl[] = [];
  selectedBranch: string;
  user = new UserInfo();

  constructor(
    private branchService: BranchService,
    private router: Router,
    private app: AppComponent,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getBranchDropdownList();

    this.route.queryParams.subscribe(params => {
      this.user.name = params["name"];
      this.user.picture = 'src\assets\images\nick.png';
    });

  }

  getBranchDropdownList() {
    this.subscriptionManager.add(this.branchService.getUserBranch().subscribe((res) => {
      if (res != null)
        this.branchDropdownList = res['data'];
    }, (err) => {
    }));
  }

  selectBranch() {
    this.spinner = true;
    localStorage.setItem('tpsBranch', this.selectedBranch);
    this.app.gLoginBranch = this.selectedBranch;
    this.router.navigate(['/home'], { queryParams: this.user });
    this.spinner = false;
  }

  ngOnDestroy() {
    if (this.subscriptionManager) {
      this.subscriptionManager.unsubscribe();
    }
  }
  LogoutMe() {
    localStorage.removeItem('tpsToken');
    localStorage.removeItem('tpsBranch');
    this.router.navigate(['/auth']);
  }

}
