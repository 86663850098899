import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';
import { MatDtl } from '../masterModel';
import { MastersService } from '../masters.service';
import { NbToastrService } from '@nebular/theme';


@Component({
  selector: 'ngx-mat-create',
  templateUrl: './mat-create.component.html',
  styles: []
})
export class MatCreateComponent implements OnInit, OnDestroy {

  modalHeader: string;
  spinner: boolean;
  subscriptionManager = new Subscription();

  sObj: MatDtl = new MatDtl();
  OldName: string;
  MatData = [];

  constructor(
    private activeModal: NgbActiveModal,
    private sMasterService: MastersService,
    private sToastrService: NbToastrService
  ) { }

  ngOnInit() {
    if (this.sObj.MatPRefNo) {
      this.OldName = this.sObj.MatName;
    }
  }

  closeModal() {
    this.activeModal.close();
  }

  saveMatData() {

    if (this.sObj.MatName != "") {
      if (this.sObj.MatPRefNo) {
        {
          this.updateMatObj();
        }
      } else {
        this.addMatObj();
      }
    }
  }

  addMatObj() {
    this.spinner = true;
    this.subscriptionManager.add(this.sMasterService.addMaterial(this.sObj).subscribe((res) => {
      if (res['StatusCode'] == 501) {
        this.sToastrService.danger('Duplicate Material', 'Material Details');
        this.activeModal.close(null);
      }
      else {

        this.activeModal.close(res['data']);
        this.spinner = false;
      }
    }, (err) => {
      this.spinner = false;
    }));
  }

  updateMatObj() {
    this.spinner = true;
    this.subscriptionManager.add(this.sMasterService.updateMaterial(this.sObj).subscribe((res) => {
      if (res['StatusCode'] == 501) {
        this.sObj.MatName = this.OldName;
        this.sToastrService.danger('Duplicate Material', 'Material Details');
        this.activeModal.close(null);
      }
      else {
        this.activeModal.close(res['data']);
      }
      this.spinner = false;
    }, (err) => {
      this.spinner = false;
    }));
  }

  ngOnDestroy() {
    if (this.subscriptionManager) {
      this.subscriptionManager.unsubscribe();
    }
  }



}
