import { ElementRef, HostListener, Directive, forwardRef, Attribute } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn, FormControl } from '@angular/forms';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[appIsNumber]',
})

export class IsNumberDirective {
    private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-'];

    constructor(private el: ElementRef) {
    }
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }
}

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[appEmailValidate][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: EmailValidator, multi: true },
    ],
})

export class EmailValidator implements Validator {
    validator: ValidatorFn;
    constructor() {
        this.validator = validateEmailFactory();
    }
    validate(c: FormControl) {
        return this.validator(c);
    }
}

function validateEmailFactory(): ValidatorFn {
    return (c: AbstractControl) => {
        if (c.value) {
            // tslint:disable-next-line:max-line-length
            if (c.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
                return null;
            } else {
                return {
                    appEmailValidate: true,
                };
            }
        }
    };
}


@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[appPhoneValidate][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: PhoneValidator, multi: true },
    ],
})

export class PhoneValidator implements Validator {
    validator: ValidatorFn;
    constructor() {
        this.validator = validatePhoneFactory();
    }
    validate(c: FormControl) {
        return this.validator(c);
    }
}

function validatePhoneFactory(): ValidatorFn {
    return (c: AbstractControl) => {
        if (c.value) {
            if (c.value.match(/^[89]\d{8}$/) || c.value.match(/^[6]\d{8}$/)) {
                return null;
            } else {
                return {
                    appPhoneValidate: true,
                };
            }
        }
    };
}
