import { Component, OnDestroy, Input } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';
import { MENU_ITEMS1 } from '../menu';
import { SharedService } from '../../../shared/service/shared.service'
import { Subscription } from 'rxjs';
import { UserMenu } from '../layoutModel';
import * as _ from "lodash";


@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <nb-menu [items]="menu1" *ngIf="menuFlag==1"  autoCollapse="true" style="background-color: darkslateblue; color:white"></nb-menu>
      </nb-sidebar>

      <nb-layout-column>
        <router-outlet></router-outlet>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnDestroy {

  private alive = true;

  menu1 = MENU_ITEMS1;    //mahesh
  menuFull = MENU_ITEMS1;    //mahesh


  UserManager = new Subscription();
  menuFlag: number = 1;
  userType: string = '';
  cmpType: string = '';

  menuList: UserMenu[] = [];

  @Input() spinner: boolean;

  currentTheme: string;
  constructor(
    protected themeService: NbThemeService,
    private sService: SharedService
  ) {
    this.themeService.getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe(theme => {
        this.currentTheme = theme.name;
      });

    this.userType = sService.gAAdmin();
    this.cmpType = sService.gGetCmpType();
    this.menuFlag = 1;
  }

  ngOnInit() {
    this.spinner = true;
    // this.SaveMenu();
    this.UserManager.add(this.sService.GetMenuList().subscribe((res) => {
      this.spinner = false;
      debugger;
      if (res == null) return;
      if (res['data'] != null) {
        this.menuList = res['data'];
        this.removeMenu();
      }
    }, (err) => {
      this.spinner = false;
    }));
  }

  removeMenu() {

    var i;
    var j;
    this.menu1 = _.cloneDeep(this.menuFull);
    //Hide Main menu which does not include cmpType either of [TR, TD]
    for (i = 0; i < this.menu1.length; i++) {
      if (!this.menu1[i].target.includes(this.cmpType)) {
        this.menu1[i].hidden = true;
      }
      else {
        if (this.menu1[i].children == undefined) continue;
        if (this.menu1[i].children != null) {
          //Hide Child Menu which does not include cmpType
          for (j = 0; j < this.menu1[i].children.length; j++) {
            if (!this.menu1[i].children[j].target.includes(this.cmpType)) {
              this.menu1[i].children[j].hidden = true;
            }
          }
        }
      }
    }

    //Add New Company 1005, hidden is false -i.e. it will show if login from Recursive
    if (this.userType == "AAdmin") {
      this.menu1[10].children[5].hidden = false;
    }
    else {
      this.menu1[10].children[5].hidden = true;
    }

    if (this.userType != "Admin" && this.userType != "SAdmin" && this.userType != "AAdmin") {
      for (i = 0; i < this.menu1[10].children.length; i++) {
        if (this.menu1[10].children[i].title == 'User Management') {
          //splice will remove elements from array and reindex the next array element
          //this.menu1[10].children.splice(i, 1);
          this.menu1[10].children[0].hidden = true;
        }
      }
    }

    for (i = 0; i < this.menuList.length; i++) {
      var mainMenu = 0;
      var subMenu = 0;
      var menuCode = this.menuList[i].MenuCode;
      var MenuCap = this.menuList[i].MenuCap;


      mainMenu = Number(this.menuList[i].MenuCode.substring(0, 2));

      if (this.menuList[i].MenuCode.length > 2) {
        subMenu = Number(this.menuList[i].MenuCode.substring(2, 4));

        if (this.menuList[i].MenuCap != 'User Management')
          //this.menu1[mainMenu - 1].children.splice(subMenu - 1, 1);
          this.menu1[mainMenu - 1].children[subMenu - 1].hidden = true;
      }
      else {
        //this.menu1.splice(mainMenu - 1, 1);
        this.menu1[mainMenu - 1].hidden = true;
      }
    }
  }
  SaveMenu() {
    this.UserManager.add(this.sService.SaveMenuDtl(this.menu1).subscribe((res) => {
      if (res != null) {
        //        console.log(res);
      }
    }));
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
