export class AppConfig {


    public static ACCOUNT = 'account';
    public static LR = 'lr';
    public static CITY = 'city';
    public static STATE = 'state';
    public static BRANCH = 'branch';
    public static MATERIAL = 'material';
    public static CHALAN = 'chalan';
    public static LR_ITEM = 'lrItem';
    public static RBRANCH = 'RBRANCH';

    public static getStationList = 'api/Station/GetStationList';
    public static addStation = "api/Station/SaveStation";
    public static updateStation = "api/Station/UpdateStation";
    public static deleteStation = "api/Station/DeleteStation";
    public static getStateList = "api/Station/GetStateList";

    public static BranchGetById = "api/Branch/GetBranchById";
    public static BranchAdd = "api/Branch/SaveBranch";
    public static BranchUpdate = "api/Branch/UpdateBranch";
    public static BranchDelete = "api/Branch/DeleteBranch";
    public static BranchList = "api/Branch/GetBranchList";

    public static LrSaveFullLoad = "api/Builty/SaveBuiltyFullLoad";
    public static LrUpdateFullLoad = "api/Builty/UpdateBuiltyFullLoad";
    public static LrAdd = "api/Builty/SaveBuilty";
    public static LrUpdate = "api/Builty/UpdateBuilty";
    public static LrDelete = "api/Builty/DeleteBuilty";
    //public static getLrByIdFull = "api/Builty/GetLrByCode";
    public static getLrByIdPart = "api/Builty/GetBuiltyByCode";
    public static findLrByLrInfo = "api/Builty/FindBuiltyByLrInfo";
    public static updateDlv = "api/Builty/UpdateDelivery";
    public static deleteDlv = "api/Builty/DeleteDelivery";
    public static addLrVoucher = "api/Builty/SaveLrVoucher";
    public static getFreightCharges = "api/User/GetFrCharges";
    public static printLrByIdPart = "api/Builty/printLrByIdPart";

    public static getMatDelivery = "api/Lr/getMatDelivery";
    //public static getMatDlvById = "api/Lr/getMatDlvById";
    public static getLrList = "api/Lr/getLrList";
    public static getLrSummary = "api/builty/GetLrSummary"; //GetLrTripSummary
    public static getLrTripSummary = "api/builty/GetLrTripSummary"; //GetLrTripSummary
    public static getDlvSummary = "api/builty/GetDlvSummary";
    public static getHomeLrSummary = "api/builty/GetHomeLrSummary";

    //Utility
    public static updateBillCharge = "api/User/UpdateBillCharge";
    public static getBcList = "api/User/GetBcList";
    public static getOptionac = "api/User/GetOptionAc";
    public static updateOptionac = "api/User/UpdateOptionAc";
    public static getOptionEmail = "api/User/GetOptionEmail";
    public static updateOptionEmail = "api/User/UpdateOptionEmail";
    public static getDashboardCount = "api/User/GetDashboardCount";

    public static getOptionInv = "api/User/GetOptionInv";
    public static updateOptionInv = "api/User/UpdateOptionInv";

    //User
    public static getUserByRefNo = "api/User/getUserByRefNo";
    public static GetAdminMenuList = "api/User/GetAdminMenuList";
    public static getUserList = "api/User/GetUserList";
    public static addLoginUser = "api/User/SaveUser";
    public static updateUser = "api/User/UpdateUser";
    public static UserBrList = "api/User/GetUserBrList";
    public static deleteUser = "api/User/DeleteUser";
    public static CheckCmpId = 'api/User/checkCmpId';
    public static getCmpInfo = "api/User/GetCmpInfo";
    public static GetFirmInfo = "api/User/GetFirmInfo";
    public static saveFirmInfo = "api/User/SaveFirmInfo";
    public static FrmDocDtl = "api/User/FrmDocDtl";
    public static GetFrmDoc = "api/User/GetFrmDoc";
    public static DeleteFrmDoc = "api/User/DeleteDoc";
    public static saveCmpInfo = "api/User/SaveCmpInfo";
    public static getCmpList = "api/User/GetCmpList";
    public static deleteCmp = "api/User/DeleteCmp";
    public static editCmpInfo = "api/User/EditCmpInfo";
    public static CheckUser = "api/User/CheckUser";
    public static ImportStationList = "api/Station/ImportStationList";
    public static ImportVHList = "api/Vehicle/ImportVHList";
    public static ImportMatList = "api/Material/ImportMatList";
    public static ImportDrList = "api/Driver/ImportDrList";
    public static ImportAcmList = "api/Account/ImportAcmList";
    public static ImportLrList = "api/Builty/ImportLrList";

    public static GetMenuList = "api/User/GetMenuList";
    public static SaveMenuDtl = "api/User/SaveMenuDtl";


    //MRS
    public static MrsAdd = "api/Mrs/SaveMrs";
    public static MrsUpdate = "api/Mrs/UpdateMrs";
    public static deleteMrs = "api/Mrs/DeleteMrs";
    public static getMrsSummary = "api/Mrs/GetMrsSummary";
    public static pdfMrsSummary = "api/Mrs/pdfMrsSummary";
    public static MRListInMrs = "api/Mrs/GetMRListInMrs";
    public static getMrsById = "api/Mrs/GetMrsById";
    public static getMrsByMrsInfo = "api/Mrs/GetMrsByMrsInfo";


    //Collection Voucher
    public static getLRListInColVou = "api/Collection/GetLRListInColVou";
    public static addColVou = "api/Collection/SaveColVoucher";
    public static updateColVou = "api/Collection/UpdateColVoucher";
    public static getColVouById = "api/Collection/GetColVouById";
    public static getColVouByNo = "api/Collection/getColVouByNo";
    public static getColVouSummary = "api/Collection/GetCollectionSummary";
    public static delColVou = "api/Collection/DeleteColVoucher";

    //General Voucher

    public static getVrDtlByCode = "api/Voucher/GetVoucherByCode";
    public static saveVrDtl = "api/Voucher/SaveVoucher";
    public static updateVrDtl = "api/Voucher/UpdateVoucher";
    public static getVrSummary = "api/Voucher/GetVoucherSummary";
    public static deleteVrDtl = "api/Voucher/DeleteVoucher";
    public static getTrBal = "api/Voucher/GetLedger";
    public static getBSheet = "api/Voucher/GetBSheet";
    public static printReport = "api/Voucher/printReport";
    public static getTrBalReport = "api/Voucher/getTrBalReport";
    public static getTrBalReportExcel = "api/Voucher/getTrBalReportExcel";

    //Vehicle    
    public static addVeh = "api/Vehicle/SaveVehicle";
    public static getVehList = "api/Vehicle/GetVehicleList";
    public static getVehicleById = "api/Vehicle/GetVehicleById";
    public static updateVeh = "api/Vehicle/UpdateVehicle";
    public static deleteVeh = "api/Vehicle/DeleteVehicle";
    public static getVhCatList = "api/Vehicle/GetVhCatList";
    public static GetDocList = "api/Vehicle/GetDocList";
    public static SaveDoc = "api/Vehicle/SaveDoc";
    public static GetViewDoc = "api/Vehicle/GetViewDoc";
    public static DeleteDoc = "api/Vehicle/DeleteDoc";

    //ACM
    public static addAcm = "api/Account/SaveAccount";
    public static getAcmList = "api/Account/GetAcmList";
    public static getAcmListForInv = "api/Account/GetAcmListForInv";
    public static getAcmListForChln = "api/Account/getAcmListForChln";
    public static getAcmListForCombo = "api/Account/GetAcmListForCombo";
    public static getAcmById = "api/Account/GetAcmById";
    public static getAcmPndBill = "api/Account/GetAcmPndBill";
    public static deleteAcm = "api/Account/DeleteAccount";
    public static updateAcm = "api/Account/UpdateAccount";
    public static getAcAdd = "api/Account/getAcAdd";
    public static getVrTypeList = "api/VrType/GetVrTypeList";
    public static getAddressList = "api/Account/GetAddressList";
    public static saveAddressdtl = "api/Account/SaveAddressDtl";
    public static updateAddressdtl = "api/Account/UpdateAddressDtl";
    public static deleteAddressdtl = "api/Account/DeleteAddressDtl";
    public static getAddressById = "api/Account/GetAddressById";

    //AcGroup
    public static getAcTypeList = "api/Acgroup/GetAcGroupList";
    public static saveAcGrp = "api/Acgroup/SaveAcGroup";
    public static updateAcGrp = "api/Acgroup/UpdateAcGroup";

    //Employee
    public static addEmp = "api/Employ/SaveEmploy";
    public static getEmpList = "api/Employ/GetEmployList";
    public static getEmpByName = "api/Employ/GetEmployById";
    public static updateEmp = "api/Employ/UpdateEmploy";
    public static deleteEmp = "api/Employ/DeleteEmploy";

    //Driver
    public static addDrv = "api/Driver/SaveDriver";
    public static getDrvList = "api/Driver/GetDriverList";
    public static getDrvById = "api/Driver/GetDriverById";
    public static deleteDrv = "api/Driver/DeleteDriver";
    public static updateDrv = "api/Driver/UpdateDriver";


    //Material
    public static addMat = "api/Material/SaveMaterial";
    public static getMatList = "api/Material/GetMatList";
    public static getMatById = "api/Material/GetMatbyId";
    public static deleteMat = "api/Material/DeleteMaterial";
    public static updateMat = "api/Material/UpdateMaterial";


    //Unit
    public static addUnit = "api/Material/SaveUnit";
    public static getUnitList = "api/Material/GetUnitList";
    public static getUnitById = "api/Material/GetUnitById";
    public static deleteUnit = "api/Material/DeleteUnit";
    public static updateUnit = "api/Material/UpdateUnit";



    public static addPacking = "api/Packing/SavePacking";
    public static getPackingList = "api/Packing/GetPackingList";
    public static getPackingById = "api/Packing/GetPackingbyId";
    public static deletePacking = "api/Packing/DeletePacking";
    public static updatePacking = "api/Packing/UpdatePacking";


    public static addChln = "api/Challan/SaveChln";
    public static getChlnList = "api/Challan/getChlnList";
    public static updateChln = "api/Challan/UpdateChln";  //-------------
    public static deleteChln = "api/Challan/DeleteChln";
    public static updateChlnAmt = "api/Challan/updateChlnAmt";
    public static getLrListInChln = "api/Builty/GetLrListByChlnCode";
    public static getChlnById = "api/Challan/GetChlnByCode";
    public static getChlnByChInfo = "api/Challan/getChlnByChInfo";
    public static getChlnSummary = "api/Challan/getChlnSummary";
    public static getChlnVoucher = "api/Challan/GetChlnVoucher";
    public static addChlnVoucher = "api/Challan/SaveChlnVoucher"; //---------
    public static printChlnReport = "api/Challan/printChlnReport";

    public static getTripIncome = "api/Challan/getTripIncome";


    public static saveTrip = "api/Trip/SaveTrip";
    public static updaeTrip = "api/Trip/UpdateTrip";
    public static deleteTrp = "api/Trip/DeleteTrip";
    public static getTrpById = "api/Trip/GetTripByCode";
    public static getTrpSummary = "api/Trip/GetTripSummary";
    public static getTrpVoucher = "api/Trip/GetTripVoucher";
    public static getTrpChallan = "api/Trip/GetTripInLr";

    public static addMr = "api/RefVoucher/SaveMr";
    public static updateMr = "api/RefVoucher/UpdateMr";
    public static deleteMr = "api/RefVoucher/DeleteMrDtl";
    public static deleteAg = "api/RefVoucher/DeleteAgDtl";
    public static getMrSummary = "api/RefVoucher/GetMrSummary";
    public static getAgSummary = "api/RefVoucher/GetAgSummary";
    public static getMrDtlById = "api/RefVoucher/GetMrDtlById";
    public static getMrList = "api/RefVoucher/getMrList";
    public static pdfReport = "api/RefVoucher/pdfReport";
    public static mrPdfReport = "api/RefVoucher/mrPdfReport";

    public static getAgDtlById = "api/RefVoucher/GetAgDtlById";
    public static saveVrAg = "api/RefVoucher/SaveRefVoucher";
    public static updateVrAg = "api/RefVoucher/UpdateRefVoucher";
    public static deleteVrAg = "api/RefVoucher/DeleteRefVoucher";
    public static getOsList = "api/RefVoucher/GetOsList";
    public static getOsBillSummary = "api/RefVoucher/GetOsBillSummary";
    public static getOsChlnSummary = "api/RefVoucher/GetOsChlnSummary";
    public static getAddressInfo = "api/AgDtl/getAddress";


    //Invoice
    public static updateInv = "api/invoice/UpdateInvoice";
    public static deleteInv = "api/invoice/DeleteInvoice";
    public static getLrListByInvCode = "api/builty/GetLrListByInvCode";
    public static getInvByCode = "api/invoice/GetInvoiceByCode";
    public static getInvByInvNo = "api/invoice/GetInvoiceByInvNo";
    public static getInvListZZ = "api/Invoice/getInvList";
    public static getInvSummary = "api/invoice/GetInvSummary";
    public static getInvGstSummary = "api/invoice/GetInvGstSummary";
    public static pdfFreightInvoice = "api/invoice/PdfFreightInvoice";
    // public static MakePdfInvoice = "api/invoice/MakePdfInvoice";
    public static GetTCForFront = "api/Views/GetTCForFront";
    public static GetCmpLh = "api/invoice/GetCmpLh";
    public static saveInv = "api/invoice/SaveInvoice";
    public static getServerPath = "api/invoice/CreateMyFolder";

    public static getInvTcList = "api/invoice/GetInvTcList";
    public static saveInvTc = "api/invoice/SaveInvTc"
    public static updateInvTc = "api/invoice/UpdateInvTc"
    public static deleteInvTc = "api/invoice/DeleteInvTc"
    public static getInvTc = "api/invoice/GetInvTc"

    public static getTradInvSummary = "api/tradinvoice/GetTradInvSummary";
    public static saveTradInvoice = "api/tradinvoice/SaveTradInvoice";
    public static updateTradInvoice = "api/tradinvoice/UpdateTradInvoice";
    public static getTradInvoiceByCode = "api/tradinvoice/GetTradInvoiceByCode";
    public static deleteTradInvoice = "api/tradinvoice/DeleteTradInvoice";
    public static pdfTradInvoice = "api/tradinvoice/PdfTradInvoice";
    public static eInvTradInvoice = "api/tradinvoice/EInvTradInvoice";
    public static updateIrnTradInvoice = "api/tradinvoice/UpdateIrnTradInvoice";
    //

    //
    //Unloading
    public static saveULd = "api/Unloading/SaveUnloading";
    public static updateULd = "api/Unloading/UpdateUnloading";
    public static deleteULd = "api/Unloading/DeleteUnloading";
    public static getULdList = "api/Unloading/getULdList";
    public static getULdByCode = "api/Unloading/GetULdByCode";
    public static getULdByULdNo = "api/Unloading/GetULdByULdNo";
    public static getLrListByUnCode = "api/Unloading/GetLrListByUnCode";
    public static getLrListByChNo = "api/Unloading/GetLrListByChlnInfo";
    public static getULdSummary = "api/Unloading/GetULdSummary";

    //General
    public static getRepGrpName = "api/Views/GetRepGrpName";
    public static getRepColMas = "api/Views/GetRepColMas";
    public static getViewListByCmp = "api/Views/GetViewListByCmp";
    public static getViewsByGrp = "api/Views/GetViewsByGrp";
    public static getVwColsByGrp = "api/Views/GetVwColsByGrp";
    public static getRepsList = "api/Views/GetRepsList";
    public static getRepColList = "api/Views/GetRepColList";
    public static getViewByRefNo = "api/Views/GetViewByRefNo";


    //GetViewByRefNo

    public static saveView = "api/Views/SaveView";
    public static updateView = "api/Views/UpdateView";
    public static deleteView = "api/Views/DeleteView";

    public static getDocumentGrpList = 'api/Views/GetDocumentGrpList';
    public static getDocList = 'api/Views/GetDocumentList';
    public static addDoc = "api/Views/SaveDocument";
    public static updateDoc = "api/Views/UpdateDocument";
    public static deleteDoc = "api/Views/DeleteDocument";


    //Item Master
    public static getPartList = "api/VehicleVM/GetVmItemList";
    public static getMakeList = "api/VehicleVM/GetVmMakeList";
    public static getPartCatList = "api/VehicleVM/GetItCatList";
    public static getVhExpGroup = "api/VehicleVM/GetExpGroupList";
    public static addPart = "api/VehicleVM/SaveVhItem";
    public static updatePart = "api/VehicleVM/UpdateVhItem";
    public static getPartById = "api/VehicleVM/GetVhItemById";
    public static deletePart = "api/VehicleVM/deletePart";
    public static getPartCatById = "api/VehicleVM/DeleteVhItem";

    public static addPartCat = "api/VehicleVM/SaveVhItemCat";
    public static updatePartCat = "api/VehicleVM/UpdateItemCat";
    public static deletePartCat = "api/VehicleVM/DeleteItemCat";

    public static addVmMake = "api/VehicleVM/SaveVhItemMake";
    public static updateVmMake = "api/VehicleVM/UpdateItemMake";
    public static deleteVmMake = "api/VehicleVM/DeleteItemMake";

    public static getItemGradeList = "api/VehicleVM/GetItemGradeList";
    public static getItemGradeById = "api/VehicleVM/GetItemGradeById";
    public static addItemGrade = "api/VehicleVM/SaveItemGrade";
    public static updateItemGrade = "api/VehicleVM/UpdateItemGrade";
    public static deleteItemGrade = "api/VehicleVM/DeleteItemGrade";



    public static getVmMakeById = "api/VehicleVM/getVmMakeById";

    public static getVmDetailById = "api/VehicleVM/GetVmDetailById";
    public static saveVmPurchase = "api/VehicleVM/SaveVmPurchase";
    public static getVmRmvDetailById = "api/VehicleVM/GetVmRmvDetailById";

    public static getVmPurPartyList = "api/VehicleVM/getVmPurPartyList";
    public static getVmPurPartyById = "api/VehicleVM/getVmPurPartyById";

    public static updateVmPurParty = "api/VehicleVM/updateVmPurParty";
    public static deleteVmPurParty = "api/VehicleVM/deleteVmPurParty";
    public static getVmPurList = "api/VehicleVM/getVmPurList";

    //VM Register
    public static getVmPurSummary = "api/VehicleVM/GetVmPurSummary";
    public static getVmItemSummary = "api/VehicleVM/GetVmItemSummary";
    public static getVmStrStock = "api/VehicleVM/GetVmStrStock";
    public static getVehCurParts = "api/VehicleVM/GetVehCurParts";
    public static getFindTyreNo = "api/VehicleVM/GetFindTyreNo";

    //WorkShop
    public static saveWsPurchase = "api/WorkShop/SaveWsPurchase";
    public static getVehLedger = "api/WorkShop/GetVehLedger";
    public static updateWsPurchase = "api/WorkShop/UpdateWsPurchase";
    public static deleteWsPurchase = "api/WorkShop/DeleteWsPurchase";

    public static saveWsRmvParts = "api/WorkShop/SaveWsRmvParts";
    public static updateWsRmvParts = "api/WorkShop/UpdateWsRmvParts";
    public static getVehRmvParts = "api/VehicleVM/GetVehRmvParts";
    public static deleteWsRmvParts = "api/WorkShop/DeleteWsRmvParts";

    public static getRprParts = "api/VehicleVM/GetRprParts";


    ////
    //Print
    public static mrPrint = "api/Print/getMrPrint";
    public static dlvPrint = "api/Print/getDlvPrint";
    public static invPrint = "api/Print/getInvPrint";

}
