import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';
import { AcmService } from '../AcmService'

import { NbToastrService } from '@nebular/theme';
import { Acm, AcGrp } from '../AcmModel';
import { CommonService } from '../../shared/service/common.service';
import { LrAcm } from '../../lr-mod/LrModel';


@Component({
  selector: 'ngx-ac-short',
  templateUrl: './ac-short.component.html',
  styleUrls: ['./ac-short.component.scss']
})
export class AcShortComponent implements OnInit, OnDestroy {

  scManager = new Subscription();

  AcmCmb: LrAcm[] = [];
  AcTypeData: AcGrp[] = [];
  AcmData: Acm = new Acm();

  @Input() eAcPUnkCode;
  @Input() spinner: boolean;

  toasterTitle = 'Account';


  constructor(private sAcmService: AcmService,
    private toasterService: NbToastrService,
    private sCommonService: CommonService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.getAcType();
  }

  getAcType() {
    this.spinner = true;
    this.scManager.add(this.sCommonService.getAcType().subscribe((res) => {
      this.spinner = false;
      if (res == null) return;
      if (res["data"] == null) return;
      this.AcTypeData = res["data"];
    }, (err) => {
      this.spinner = false;
    }));
  }

  getAcmDtlByName() {
    this.spinner = true;
    this.scManager.add(this.sAcmService.getAcmDetailById(this.eAcPUnkCode).subscribe((res) => {
      this.spinner = false;
      if (res == null) return;
      if (res["data"] == null) return;
      this.AcmData = res["data"];
    }, (err) => {
      this.spinner = false;
      this.toasterService.info("Error while saving....", this.toasterTitle);;
    }));
  }


  SaveAcm() {
    if (this.AcmData.AcName == undefined || this.AcmData.AcName == "") {
      this.toasterService.danger("Can't Save  A/c. Name should not be Blank....", this.toasterTitle);
      return;
    }
    if (this.AcmData.AcType == undefined || this.AcmData.AcType == "") {
      this.toasterService.danger("Can't Save  A/c. Type should not be Blank....", this.toasterTitle);
      return;
    }

    if (this.AcmData.AcOpCrDr == undefined && Number(this.AcmData.AcOpBal) > 0) {
      this.toasterService.danger("Can't Save  Cr/Dr should not be Blank....", this.toasterTitle);
      return;
    }

    if (this.AcmData.AcPtType == undefined || this.AcmData.AcPtType == "") this.AcmData.AcPtType = "Ac";


    var tGrp = this.AcTypeData.filter(data => {
      if (data.GrpName == this.AcmData.AcType)
        return data.BankStat;
    });

    if (tGrp != null) {
      this.AcmData.AcBankStat = tGrp[0].BankStat;
      this.AcmData.GrpFRefNo = tGrp[0].GrpPRefNo;
    }


    if (this.AcmData.AcPUnkCode != "") {
      this.updateAcm()
    }
    else {
      this.ReplAcmData();
    }
  }

  async ReplAcmData() {
    this.spinner = true;
    this.scManager.add(await this.sAcmService.createAcmPromise(this.AcmData).then((res) => {
      if (res['StatusCode'] == 501) {
        this.toasterService.info('Duplicate Entry...', this.toasterTitle);
      }
      else {
        this.toasterService.success('Created successfully', this.toasterTitle);

        let sObj = new LrAcm();
        sObj.AcName = res['data'].AcName;
        sObj.AcPRefNo = res['data'].AcPRefNo;
        sObj.AcPtType = res['data'].AcPtType;
        this.AcmCmb.push(sObj);
        this.spinner = false;
        this.activeModal.close(this.AcmCmb);
      }
    }, (err) => {
      if (err.status == 500) this.toasterService.info('Error....', this.toasterTitle);
      else if (err.status == 501) this.toasterService.info('Duplicate Entry', this.toasterTitle);
    }));
    this.spinner = false;
  }

  updateAcm() {
    this.spinner = true;
    this.scManager.add(this.sAcmService.updateAcm(this.AcmData).subscribe((res) => {
      if (res['StatusCode'] == 501) this.toasterService.info('Duplicate Entry...', this.toasterTitle);
      else {

        this.AcmCmb.map(data => {
          if (this.AcmData.AcPRefNo == data.AcPRefNo) {
            data.AcName = this.AcmData.AcName;
          }
        })

        this.toasterService.success('Updated successfully', this.toasterTitle);
        this.spinner = false;
        this.activeModal.close(this.AcmCmb);
      }
    }, (err) => {
    }));
    this.spinner = false;
  }

  closeModal() {
    this.activeModal.close();
  }

  ngOnDestroy() {
    if (this.scManager) this.scManager.unsubscribe();
  }


}
