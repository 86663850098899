import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';
import { InvPrint } from '../InvModel'

@Component({
  selector: 'ngx-inv-print-dialog',
  templateUrl: './inv-print-dialog.component.html',
  styleUrls: ['./inv-print-dialog.component.scss']
})
export class InvPrintDialogComponent implements OnInit, OnDestroy {

  sObj: InvPrint = new InvPrint();

  modalHeader: string;
  spinner: boolean;
  subscriptionManager = new Subscription();

  constructor(
    private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscriptionManager) {
      this.subscriptionManager.unsubscribe();
    }
  }

  closeModal() {
    this.activeModal.close();
  }

  findInvData() {
    this.activeModal.close(this.sObj);
  }

}
