import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AppConfig } from '../app.config';
import { StationDtl, MatDtl, BranchDtl, PkTypeDtl, DocDtl, UnitDtl } from '../masters/masterModel';

@Injectable({
  providedIn: 'root'
})
export class MastersService {

  constructor(
    private http: HttpClient,
  ) { }

  getBranchList() {
    var url = environment.HOST_URL + AppConfig.BranchList;
    return this.http.get<BranchDtl[]>(url);
  }

  addBranch(params: BranchDtl) {
    return this.http.post<BranchDtl>(environment.HOST_URL + AppConfig.BranchAdd, params);
  }

  updateBranch(params: BranchDtl) {
    return this.http.post<BranchDtl>(environment.HOST_URL + AppConfig.BranchUpdate, params);
  }

  deleteBranch(branchId) {
    var url = environment.HOST_URL + AppConfig.BranchDelete.concat('/?id=').concat(branchId);
    return this.http.get(url);
  }


  getStationList() {
    return this.http.get<StationDtl[]>(environment.HOST_URL + AppConfig.getStationList);
  }

  addStation(params: StationDtl) {
    return this.http.post<StationDtl>(environment.HOST_URL + AppConfig.addStation, params);
  }

  updateStation(params: StationDtl) {
    return this.http.post<StationDtl>(environment.HOST_URL + AppConfig.updateStation, params);
  }

  deleteStation(cityId) {
    return this.http.get(environment.HOST_URL + AppConfig.deleteStation.concat('/?id=').concat(cityId));
  }


  getMaterialList() {
    return this.http.get<MatDtl[]>(environment.HOST_URL + AppConfig.getMatList);
  }

  addMaterial(params: MatDtl) {
    return this.http.post<MatDtl>(environment.HOST_URL + AppConfig.addMat, params);
  }

  updateMaterial(params: MatDtl) {
    return this.http.post<MatDtl>(environment.HOST_URL + AppConfig.updateMat, params);
  }

  deleteMaterial(materialId) {
    return this.http.get(environment.HOST_URL + AppConfig.deleteMat.concat('/?id=').concat(materialId));
  }




  getPackingList() {
    return this.http.get<PkTypeDtl[]>(environment.HOST_URL + AppConfig.getPackingList);
  }

  addPacking(params: PkTypeDtl) {
    return this.http.post<MatDtl>(environment.HOST_URL + AppConfig.addPacking, params);
  }

  updatePacking(params: PkTypeDtl) {
    return this.http.post<MatDtl>(environment.HOST_URL + AppConfig.updatePacking, params);
  }

  deletePacking(materialId) {
    return this.http.get(environment.HOST_URL + AppConfig.deletePacking.concat('/?id=').concat(materialId));
  }

  getDocGrpList() {
    return this.http.get<DocDtl[]>(environment.HOST_URL + AppConfig.getDocumentGrpList);
  }

  getDocList() {
    return this.http.get<DocDtl[]>(environment.HOST_URL + AppConfig.getDocList);
  }

  addDoc(params: DocDtl) {
    return this.http.post<DocDtl>(environment.HOST_URL + AppConfig.addDoc, params);
  }

  updateDoc(params: DocDtl) {
    return this.http.post<DocDtl>(environment.HOST_URL + AppConfig.updateDoc, params);
  }

  deleteDoc(sId) {
    return this.http.get(environment.HOST_URL + AppConfig.deleteDoc.concat('/?id=').concat(sId));
  }





  getUnitList() {
    return this.http.get<UnitDtl[]>(environment.HOST_URL + AppConfig.getUnitList);
  }

  addUnit(params: UnitDtl) {
    return this.http.post<UnitDtl>(environment.HOST_URL + AppConfig.addUnit, params);
  }

  updateUnit(params: UnitDtl) {
    return this.http.post<UnitDtl>(environment.HOST_URL + AppConfig.updateUnit, params);
  }

  deleteUnit(materialId) {
    return this.http.get(environment.HOST_URL + AppConfig.deleteUnit.concat('/?id=').concat(materialId));
  }



}
