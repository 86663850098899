import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { OneColumnLayoutComponent } from './@theme/layouts';
import { AuthLayoutComponent } from './@theme/components/auth-layout/auth-layout.component';
import { AuthGuardChildService, AuthGuardService } from './shared/service/auth-guard.service';
import { BranchSelectionComponent } from './shared/components/branch-selection/branch-selection.component';


const routes: Routes = [
  {
    path: '',
    component: OneColumnLayoutComponent,
    canActivateChild: [AuthGuardChildService],
    canActivate: [AuthGuardService],

    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: './recursive/recursive.module#RecursiveModule',
      },
      {
        path: 'account',
        loadChildren: './ac-mod/ac-mod.module#AcModModule',
      },
      {
        path: 'voucher',
        loadChildren: './money-receipt/money-receipt.module#MoneyReceiptModule',
      },
      {
        path: 'driver',
        loadChildren: './drv/drv.module#DrvModule',
      },
      {
        path: 'employee',
        loadChildren: './employee/employee.module#EmployeeModule',
      },
      {
        path: 'vehicle',
        loadChildren: './vehicle/vehicle.module#VehicleModule',
      },
      {
        path: 'lronly',
        loadChildren: './lr-mod/lr-mod.module#LrModModule',
      },
      {
        path: 'challan',
        loadChildren: './chln-mod/chln-mod.module#ChlnModModule',
      },
      {
        path: 'unloading',
        loadChildren: './unloading/unloading.module#UnloadingModule',
      },
      {
        path: 'delivery',
        loadChildren: './mat-delivery/mat-delivery.module#MatDeliveryModule',
      },
      {
        path: 'lr',
        loadChildren: './lr-management/lr-management.module#LrManagementModule',
      },
      {
        path: 'user',
        loadChildren: './user-management/user-management.module#UserManagementModule',
      },
      {
        path: 'master',
        loadChildren: './master-management/master-management.module#MasterManagementModule',
      },
      {
        path: 'recmaster',
        loadChildren: './masters/masters.module#MastersModule',
      },
      {
        path: 'chalan',
        loadChildren: './chalan-management/chalan-management.module#ChalanManagementModule',
      },
      {
        path: 'invoice',
        loadChildren: './invoice/invoice.module#InvoiceModule',
      },
      {
        path: 'tradinvoice',
        loadChildren: './trad-invoice/trad-invoice.module#TradInvoiceModule',
        //src\app\trad-invoice\trad-invoice.module.ts
      },
      {
        path: 'trip',
        loadChildren: './trip-sheet/trip-sheet.module#TripSheetModule',
      },
      {
        path: 'mrs',
        loadChildren: './mrs/mrs.module#MrsModule',
      },
      {
        path: 'colvou',
        loadChildren: './vr-collection/vr-collection.module#VrCollectionModule',
      },
      {
        path: 'vhparts',
        loadChildren: './vh-parts/vh-parts.module#VhPartsModule',
      },
      {
        path: 'vhmaintenance',
        //src\app\vh-maintenance\vh-maintenance.module.ts
        loadChildren: './vh-maintenance/vh-maintenance.module#VhMaintenanceModule',
      },
      {
        path: 'genvoucher',
        //src\app\voucher-gen\voucher-gen.module.ts
        loadChildren: './voucher-gen/voucher-gen.module#VoucherGenModule',
      },
      {
        path: 'views',
        //src\app\view-management\view-management.module.ts
        //E:\Angular\RecWeb\RecFrontend\src\app\view-management\view-management.module.ts
        loadChildren: './view-management/view-management.module#ViewManagementModule',
      },

    ],
  },
  {
    path: 'print',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'invoice',
        loadChildren: './my-print/my-print.module#MyPrintModule',
      },
      {
        path: 'mr',
        loadChildren: './my-print/my-print.module#MyPrintModule',
      },
      {
        path: 'lr',
        loadChildren: './my-print/my-print.module#MyPrintModule',
      },
      {
        path: 'dlv',
        loadChildren: './my-print/my-print.module#MyPrintModule',
      },

    ]
  },

  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './authentication/authentication.module#AuthenticationModule',
      },
    ],
  },
  {
    path: 'branch',
    component: AuthLayoutComponent,
    canActivateChild: [AuthGuardChildService],
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: BranchSelectionComponent,
      },
    ],
  },
  // {
  //   path: '**',
  //   component: NotFoundComponent,
  // },

];



const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})

export class AppRoutingModule {
}
