
export class LrDtl {
    constructor(
        public LrMode: string = '',
        public LrLoadType: string = '',
        public LrPRefNo: number = 0,
        public LrPUnkCode: string = '',
        public LrToPay: string = '',
        public LrBrName: string = '',
        public LrNo: number = 0,
        public LrExtn: string = '',
        public LrLdDt?: Date,
        public tLrLdDt: string = '',
        public DvDt?: Date,
        public LrTm: string = '',
        public LrBpRefNo: number = 0,
        public LrBpName: string = '',

        public ChFUnkCode: string = '',
        public FrFUnkCode: string = '',
        public MrFUnkCode: string = '',
        public HdFUnkCode: string = '',
        public ClFUnkCode: string = '',

        public LrCrName: string = '',
        public LrCnName: string = '',
        public LrCtFrom: string = '',
        public LrCtTo: string = '',
        public LrMatName: string = '',
        public LrMatAmt: Number = 0,
        public LrPkgNo: number = 0,
        public LrPkgType: string = '',

        public LrInvNo: string = '',
        public LrInvDt: string = '',

        public LrShipNo: string = '',
        public LrShipDt: string = '',
        public LrGpNo: string = '',

        public LrCntrNo: string = '',
        public LrLdWt: number = 0,
        public LrChrgWt: number = 0,
        public LrULdWt: number = 0,
        public LrShrtWt: number = 0,
        public LrFrRate: number = 0,
        public LrFrAmtCal: string = '',
        public LrGstBy: string = '',
        public LrToBrName: string = '',
        public LrRemark: string = '',

        public LrDelType: string = '',
        public LrHDel: string = '',
        public LrCollection: string = '',
        public LrPvtMark: string = '',
        public LrEFrom: string = '',

        public LrVhNo: string = '',
        public LrOnAtch: string = '',
        public LrVhCat: string = '',
        public LrOdrNo: string = '',
        public LrNetKM: number = 0,

        public LrFrghtAmt: number = 0,
        public LrChrgAmt: number = 0,
        public LrLdAmt: number = 0,
        public LrColAmt: number = 0,
        public LrDrdAmt: number = 0,

        public LrULdAmt: number = 0,
        public LrDetAmt: number = 0,
        public LrDetPer: number = 0,
        public LrDetDays: number = 0,
        public LrChrgAmt8: number = 0,
        public LrChrgAmt9: number = 0,
        public LrOChAmt: number = 0,
        public LrTotFrAmt: number = 0,

        public LrGstPer: number = 0,
        public LrGstAmt: number = 0,

        public LrNetFrAmt: number = 0,

        public DvULdAmt: number = 0,
        public DvDetAmt: number = 0,
        public DvOChAmt: number = 0,
        public DlvAmt: number = 0,


        public LrAdvAmt: number = 0,
        public LrToPayAmt: number = 0,
        public LrNetBalAmt: number = 0,

        public DvNo: number = 0,
        public DvMonth: string = '',
        public DvYrMn: string = '',
        public LrPodHand: string = '',

        public ChlnPRefNo: number = 0,
        public ChBkRefNo: number = 0,
        public ChBkName: string = '',
        public ChDrvName: string = '',
        public ChNo: number = 0,
        public ChExtn: string = '',
        public ChOnAtch: string = '',
        public UnLUnkCode: string = '',
        public LrType: string = 'LR',
        public ChType: string = 'CH',
        public CrFUnkCode: string = '',
        public LrMUnkCode: string = '',
        public CrDUnkCode: string = '',
        public DrFUnkCode: string = ''
    ) { }
}

export class LrAcm {
    constructor(
        public AcPRefNo: number = 0,
        public AcName: string = '',
        public AcPtType: string = '',
        public AcIGstFlag: string = '',
        public AcPUnkCode: string = '',

    ) { }
}

export class LrFind {
    constructor(
        public LrPUnkCode: string = '',
        public LrBrName: string = '',
        public LrNo: number = 0,
        public LrExtn: string = ''
    ) { }
}



export class LrFilter {
    constructor(
        public GrpFlag: string = '',        //Pt(Party-wise)  Br(Branch-wise)  Mn(Month-wise)
        public MenuFlag: string = 'Sm',     //Sm(Summary)  Mn(Month-wise)  Det(Detail)n
        public LrNoFrom: number = 0,
        public LrNoTo: Number = 0,
        public DtFrom: Date = null,
        public DtTo: Date = null,
        public LrBrName: string = '',
        public LrToBrName: string = '',
        public LrExtn: string = '',
        public LrBpName: string = '',
        public LrBpRefNo: number = 0,
        public LrMonth: string = '',
        public LrCtTo: string = '',
        public LrVhNo: string = '',
        public LrPending: string = '',
        public LrToPay: string = '',
        public LrType: string = '',
        public LrBkType: string = 'BK', //Booking-> BK,  InComing -> IN
        public IsExportExcel: boolean = false,
        public IsExportPdf: boolean = false,
        public VwFRefNo: number = 0,
        public GrpName: string = 'LR Register'

    ) { }
}

export class VhCatDtl {
    constructor(
        public VhCatPRefNo: number = 0,
        public VhCatName: string = '',
    ) { }
}

export class LrCharge {
    constructor(
        public ChName: string = '',
        public ChVisible: string = '',
        //public ChFlag: boolean = true,
        public ChProp: string = '',
    ) { }
}

export class LrStatus {
    constructor(

        public LrPUnkCode: string = '',
        public LrBrName: string = '',
        public LrNo: number = 0,
        public LrExtn: string = '',
        public LrLdDt?: Date,
        public tLrLdDt: string = '',

        public ChFUnkCode: string = '',
        public FrFUnkCode: string = '',
        public MrFUnkCode: string = '',
        public HdFUnkCode: string = '',
        public ClFUnkCode: string = '',

        public LrCrName: string = '',
        public LrCnName: string = '',
        public LrCtFrom: string = '',
        public LrCtTo: string = '',
        public LrMatName: string = '',
        public LrPkgNo: number = 0,

        public LrInvNo: string = '',
        public LrChrgWt: number = 0,
        public LrToBrName: string = '',

        public LrPvtMark: string = '',
        public LrEFrom: string = '',

        public DvNo: number = 0,
    ) { }


}