import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';

import { ERRORCODES } from '../constants/error.constant';

@Injectable({
  providedIn: 'root',
})

export class InterceptorService implements HttpInterceptor {

  constructor(
    private toasterService: NbToastrService,
    private router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = localStorage.getItem('tpsToken');
    let authRequest;
    if (authToken) {
      authRequest = req.clone({ headers: req.headers.set('Authorization', "Bearer " + authToken) });
    } else {
      //let hdrs = new HttpHeaders({ 'Content-Type': 'application/json' });
      //authRequest = req.clone({ headers: req.headers.set("Access-Control-Allow-Origin", "*") });
      //authRequest = req.clone({ headers: req.headers.set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept") });
      //authRequest = req.clone();
      //console.log(authRequest);
      authRequest = req.clone();
    }
    return next
      .handle(authRequest)
      .do(event => {
        if (event instanceof HttpResponse) {
          this.requestSuccess(event);
        }
      })
      .catch((error: HttpErrorResponse) => {

        if (error.status == 401) {
          this.toasterService.danger('...', 'UnAuthorised Person...');
          localStorage.removeItem('tpsToken');
          this.router.navigate(['/auth']);
        }
        else if (error.error.applicationErrorCode !== undefined &&
          error.error.applicationErrorCode !== null &&
          error.error.applicationErrorCode in ERRORCODES) {
          if (error.error.applicationErrorCode === 1000 || error.status == 401) {
            this.toasterService.danger('...', ERRORCODES[error.error.applicationErrorCode]);
            this.router.navigate(['/auth']);
          } else {
            this.toasterService.danger('...', ERRORCODES[error.error.applicationErrorCode]);
          }
        } else {
          this.toasterService.danger(error);
        }
        return Observable.throw(error);
      });
  }

  private requestSuccess(res: HttpResponse<any>) {
    return res;
  }

}
