import { NgModule } from '@angular/core';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthGuardService } from './service/auth-guard.service';
import { SharedService } from './service/shared.service';
import { ThemeModule } from '../@theme/theme.module';
import { InterceptorService } from './service/interceptor.service';
import { DataService } from './service/data.service';
import { BranchSelectionComponent } from './components/branch-selection/branch-selection.component';
import { BranchService } from './components/branch-selection/branch.service';
import { IsNumberDirective, PhoneValidator, EmailValidator } from './directives/validation.directive';
import { ErrMsgComponent } from './components/error-msg/error-msg.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { NumberDirective, DateDirective, NumDotDirective, UpperCaseDirective } from './directives/numbers-only.directive';
import { AcShortComponent } from '../ac-mod/ac-short/ac-short.component';
import { MatCreateComponent } from '../masters/mat-create/mat-create.component';
import { CtCreateComponent } from '../masters/ct-create/ct-create.component';
import { VhFormComponent } from '../vehicle/vh-form/vh-form.component';
import { InvFilterComponent } from '../invoice/inv-filter/inv-filter.component';
import { MatDatepickerModule } from '@angular/material';
import { InvPrintDialogComponent } from '../invoice/inv-form/inv-print-dialog.component';
import { AcAddressDialogComponent } from '../ac-mod/ac-address-dialog/ac-address-dialog.component';
import { UnitCreateComponent } from '../masters/unit-create/unit-create.component';


@NgModule({
  imports: [
    HttpClientModule,
    Ng2SmartTableModule,
    ThemeModule, MatDatepickerModule
  ],
  declarations: [
    BranchSelectionComponent,
    IsNumberDirective,
    PhoneValidator,
    EmailValidator,
    ErrMsgComponent,
    DropdownComponent, NumberDirective, DateDirective, NumDotDirective, UpperCaseDirective,
    AcShortComponent, MatCreateComponent, CtCreateComponent, VhFormComponent, InvFilterComponent, InvPrintDialogComponent,
    AcAddressDialogComponent, UnitCreateComponent
  ],
  exports: [
    HttpClientModule,
    Ng2SmartTableModule,
    ThemeModule,
    IsNumberDirective,
    PhoneValidator,
    EmailValidator,
    ErrMsgComponent,
    DropdownComponent, NumberDirective, DateDirective, NumDotDirective, UpperCaseDirective,
    AcShortComponent, MatCreateComponent, CtCreateComponent, VhFormComponent, InvFilterComponent, UnitCreateComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    AuthGuardService,
    SharedService,
    DataService,
    BranchService,
  ],
  entryComponents: [AcShortComponent, MatCreateComponent, CtCreateComponent, VhFormComponent, InvFilterComponent,
    AcAddressDialogComponent, InvPrintDialogComponent, UnitCreateComponent]
})

export class SharedModule { }
