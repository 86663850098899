import { NbMenuItem } from '@nebular/theme';
import * as jwt_decode from 'jwt-decode';

export const MENU_ITEMS1: NbMenuItem[] = [
    {
        title: 'Dashboard',
        icon: 'nb-list',
        link: '/home/home',
        target: 'TR',
        hidden: false,
    },
    {
        title: 'Dashboard',
        icon: 'nb-list',
        link: '/home/hometrad',
        target: 'TD',
        hidden: false,
    },
    {
        title: 'Lr-Status',
        icon: 'fa fa-cubes',
        link: '/lronly/status',
        target: 'TR',
        hidden: false,
    },
    {
        title: 'Master',
        icon: 'fa fa-users',
        expanded: false,
        hidden: false,
        target: 'TR-TD',
        children: [

            {
                icon: 'nb-list nb-xs',
                // icon: 'nb-arrow-right',
                title: 'Branch',
                link: '/recmaster/recbranch',
                target: 'TR-TD',
                hidden: false,
            },
            {
                icon: 'nb-list nb-xs',
                title: 'Account',
                link: '/account/list',
                target: 'TR-TD',
                hidden: false,
            },
            {
                icon: 'nb-list nb-xs',
                title: 'Address',
                link: '/account/addresslist',
                target: 'TD',
                hidden: false,
            },
            {
                icon: 'fa fa-life-ring',
                title: 'City',
                link: '/recmaster/recstation',
                target: 'TR-TD',
                hidden: false,
            },
            {
                icon: 'fa fa-car',
                title: 'Vehicle',
                link: '/vehicle/list',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-list nb-xs',
                title: 'Material',
                link: '/recmaster/recmaterial',
                target: 'TR-TD',
                hidden: false,
            },
            {
                icon: 'nb-list nb-xs',
                title: 'Unit',
                link: '/recmaster/recunit',
                target: 'TR-TD',
                hidden: false,
            },
            {
                icon: 'nb-list nb-xs',
                title: 'Packing Type',
                link: '/recmaster/recpacking',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'fas fa-address-card fa-sm',
                title: 'Driver',
                link: '/driver/list',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'fa fa-user-circle',
                title: 'Employee',
                link: '/employee/list',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'fa fa-user-circle',
                title: 'Document',
                link: '/recmaster/recdocument',
                target: 'TR-TD',
                hidden: false,
            },
        ],
    },
    {
        title: 'Transaction',
        icon: 'fa fa-truck',
        hidden: false,
        target: 'TR-TD',
        children: [
            {
                icon: 'nb-alert',
                title: 'LR Parcel',
                link: '/lronly/create',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-alert',
                title: 'LR Full',
                link: '/lronly/createfull',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-alert',
                title: 'Loading Memo',
                link: '/lronly/createmlfull',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-chevron-right',
                title: 'Challan',
                link: '/challan/create',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-location',
                title: 'Freight Invoice',
                link: '/invoice/create',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-layout-centre',
                title: 'Unloading',
                link: '/unloading/create',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-grid-a',
                title: 'Delivery',
                link: '/delivery/create',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-grid-a',
                title: 'MRS',
                link: '/mrs/createmrs',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-grid-a',
                title: 'BRS',
                link: '/mrs/createbrs',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-grid-a',
                title: 'LHS',
                link: '/mrs/createlhs',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-grid-a',
                title: 'Sales Invoice',
                link: '/tradinvoice/tbcreate',
                target: 'TD',
                hidden: false,
            },
            {
                icon: 'nb-grid-a',
                title: 'Purchase Invoice',
                link: '/tradinvoice/pbcreate',
                target: 'TD',
                hidden: false,
            },
            {
                icon: 'nb-grid-a',
                title: 'Transport Invoice',
                link: '/tradinvoice/rtcreate',
                target: 'TD',
                hidden: false,
            },
            {
                icon: 'nb-grid-a',
                title: 'Credit Note',
                link: '/tradinvoice/crncreate',
                target: 'TD',
                hidden: false,
            },
            {
                icon: 'nb-grid-a',
                title: 'Debit Note',
                link: '/tradinvoice/dbncreate',
                target: 'TD',
                hidden: false,
            },
        ],
    },
    {
        title: 'Report',
        icon: 'fa fa-address-book',
        target: 'TR-TD',
        hidden: false,
        children: [
            {
                icon: 'nb-paper-plane',
                title: 'LR Register',
                link: '/lronly/lrsummary',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'Challan Register',
                link: '/challan/chsummarymenu',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'LHC Register',
                link: '/challan/lhcsummarymenu',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'Invoice Register',
                link: '/invoice/invsummarymenu',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'FV Register',
                link: '/invoice/fvsummarymenu',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'Sales Register',
                link: '/tradinvoice/tradtbinvsummary',
                target: 'TD',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'Purchase Register',
                link: '/tradinvoice/tradpbinvsummary',
                target: 'TD',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'Transport Register',
                link: '/tradinvoice/tradrtinvsummary',
                target: 'TD',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'Credit-Note Register',
                link: '/tradinvoice/tradcrninvsummary',
                target: 'TD',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'Debit-Note Register',
                link: '/tradinvoice/traddbninvsummary',
                target: 'TD',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'GST Register',
                link: '/invoice/invgstregister',
                target: 'TR-TD',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'Unloading Register',
                link: '/unloading/uldsummary',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-roller-shades',
                title: 'Delivery Register',
                link: '/delivery/dlvsummary',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'MR Register',
                link: '/voucher/mrsummaryall',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'Voucher Register',
                link: '/voucher/agsummarymenu',
                target: 'TR-TD',
                hidden: false,
            },
            {
                icon: 'nb-grid-a',
                title: 'MRS Register',
                link: '/mrs/mrssummary',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-grid-a',
                title: 'BRS Register',
                link: '/mrs/brssummary',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-grid-a',
                title: 'LHS Register',
                link: '/mrs/lhssummary',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-grid-a',
                title: 'Trip Register',
                link: '/trip/list',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-grid-a',
                title: 'Trip Income',
                link: '/challan/tripincome',
                target: 'TR',
                hidden: false,
            },
        ],
    },
    {
        title: 'Pending Reports',
        icon: 'fa fa-address-book',
        target: 'TR-TD',
        hidden: false,
        children: [
            {
                icon: 'nb-paper-plane',
                title: 'To Bill',
                link: '/lronly/lrpndtobill',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'To Challan',
                link: '/lronly/lrpndtochln',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'To MR',
                link: '/lronly/lrpndtomr',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'To Delivery',
                link: '/lronly/lrpndtodlv',
                target: 'TR',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'Outstanding Bill',
                link: '/voucher/osbill',
                target: 'TR-TD',
                hidden: false,
            },
            {
                icon: 'nb-paper-plane',
                title: 'Outstanding LHC',
                link: '/voucher/oslhc',
                target: 'TR',
                hidden: false,
            },
        ],

    },
    {
        title: 'Accounts',
        icon: 'fa fa-address-book',
        hidden: false,
        target: 'TR-TD',
        children: [
            {
                title: 'Account Master',
                link: '/account/create',
                target: 'TR-TD',
                hidden: false,
            },
            {
                title: 'Account Group',
                link: '/account/listacgrp',
                target: 'TR-TD',
                hidden: false,
            },
            {
                title: 'Money Receipt',
                link: '/voucher/create',
                target: 'TR',
                hidden: false,
            },
            {
                title: 'Collection Voucher',
                link: '/colvou/colvrsummary',
                target: 'TR',
                hidden: false,
            },
            {
                title: 'Home Delivery',
                link: '/colvou/hdvrsummary',
                target: 'TR',
                hidden: false,
            },
            {
                title: 'General Voucher',
                link: '/genvoucher/create',
                target: 'TR-TD',
                hidden: false,
            },
            {
                title: 'Voucher List',
                link: '/genvoucher/genvousummary',
                target: 'TR-TD',
                hidden: false,
            },
            {
                title: 'Payment of Challan',
                link: '/voucher/createaglhc',
                target: 'TR-TD',
                hidden: false,
            },
            {
                title: 'Receipt of Bill',
                link: '/voucher/createaginv',
                target: 'TR-TD',
                hidden: false,
            },
            {
                title: 'Trial Balance',
                link: '/genvoucher/menutrbal',
                target: 'TR-TD',
                hidden: false,
            },
            {
                title: 'Bank Book',
                link: '/genvoucher/menubankbook',
                target: 'TR-TD',
                hidden: false,
            },
            {
                title: 'Cash Book',
                link: '/genvoucher/menucashbook',
                target: 'TR-TD',
                hidden: false,
            },
            {
                title: 'Balance Sheet',
                link: '/genvoucher/menubsheet',
                target: 'TR-TD',
                hidden: false,
            },
            {
                title: 'Profit & Loss',
                link: '/genvoucher/menubpandf',
                target: 'TR-TD',
                hidden: false,
            },
        ],
    },
    {
        title: 'Vehicle Maintenance',
        icon: 'fa fa-address-book',
        target: 'TR',
        hidden: false,
        children: [
            {
                icon: 'nb-paper-plane',
                title: 'Vehicle Parts',
                link: '/vhparts/list',
                hidden: false,
                target: 'TR'
            },
            {
                icon: 'nb-paper-plane',
                title: 'Part Category',
                link: '/vhparts/catlist',
                hidden: false,
                target: 'TR'
            },
            {
                icon: 'nb-paper-plane',
                title: 'Make / Company',
                link: '/vhparts/makelist',
                hidden: false,
                target: 'TR'
            },
            {
                icon: 'nb-paper-plane',
                title: 'Item Grade',
                link: '/vhparts/gradelist',
                hidden: false,
                target: 'TR'
            },
            {
                icon: 'nb-paper-plane',
                title: 'Opening Stock',
                link: '/vhmaintenance/vmopbstock',
                hidden: false,
                target: 'TR'
            },
            {
                icon: 'nb-paper-plane',
                title: 'Purchase Parts',
                link: '/vhmaintenance/purchasepart',
                hidden: false,
                target: 'TR'
            },
            {
                icon: 'nb-paper-plane',
                title: 'Issue Parts',
                link: '/vhmaintenance/vmissuestrveh',
                hidden: false,
                target: 'TR'
            },
            {
                icon: 'nb-paper-plane',
                title: 'Issue for Repair',
                link: '/vhmaintenance/vmstrrpr',
                hidden: false,
                target: 'TR'
            },

            {
                icon: 'nb-paper-plane',
                title: 'Issue for Destroy',
                link: '/vhmaintenance/vmissuestrdst',
                hidden: false,
                target: 'TR'
            },

            {
                icon: 'nb-paper-plane',
                title: 'Issue to Party',
                link: '/vhmaintenance/vmstrprt',
                hidden: false,
                target: 'TR'
            },
            {
                icon: 'nb-paper-plane',
                title: 'Receive from Repair',
                link: '/vhmaintenance/vmrprrtn',
                hidden: false,
                target: 'TR'
            },

            {
                icon: 'nb-paper-plane',
                title: 'Remove Parts',
                link: '/vhmaintenance/vmrmvparts',
                hidden: false,
                target: 'TR'
            },
            {
                icon: 'nb-paper-plane',
                title: 'Transfer to Other Branch',
                link: '/vhmaintenance/vmstrtobranch',
                hidden: false,
                target: 'TR'
            },
        ]

    },

    {
        title: 'VM Report',
        icon: 'fa fa-address-book',
        target: 'TR',
        hidden: false,
        children: [
            {
                icon: 'nb-paper-plane',
                title: 'Purchase Summary',
                link: '/vhmaintenance/vmpursum',
                hidden: false,
                target: 'TR'
            },
            {
                icon: 'nb-paper-plane',
                title: 'Parts Movement',
                link: '/vhmaintenance/vmitemmove',
                hidden: false,
                target: 'TR'
            },
            {
                icon: 'nb-paper-plane',
                title: 'Workshop Stock',
                link: '/vhmaintenance/vmstrstock',
                hidden: false,
                target: 'TR'
            },
            {
                icon: 'nb-paper-plane',
                title: 'Current Vehicle Parts',
                link: '/vhmaintenance/vmvehcurrentparts',
                hidden: false,
                target: 'TR'
            },
            {
                icon: 'nb-paper-plane',
                title: 'Find Tyre No',
                link: '/vhmaintenance/vmfindtyreno',
                hidden: false,
                target: 'TR'
            },
            {
                icon: 'nb-paper-plane',
                title: 'Vehicle Ledger',
                link: '/vhmaintenance/vhledger',
                hidden: false,
                target: 'TR'
            },
        ]

    },
    {
        title: 'Utility',
        icon: 'fa fa-address-book',
        hidden: false,
        target: 'TR-TD',
        children: [
            {
                title: 'User Management',
                link: '/user',
                target: 'TR-TD',
                hidden: false,
                //hidden: !findRole()                //!findRole([1], Number(tokenPayload.userRole)),
            },
            {
                title: 'Company Profile',
                link: '/user/getcompany',
                target: 'TR-TD',
                hidden: false,
            },
            {
                title: 'Bill Charges',
                link: '/user/billcharge',
                target: 'TR',
                hidden: false,
            },
            {
                title: 'Invoice Condition',
                link: '/invoice/invtclist',
                target: 'TR-TD',
                hidden: false,
            },
            {
                title: 'View List',
                link: '/views/list',
                target: 'TR',
                hidden: false,
            },
            {
                title: 'Add New Company',
                link: '/user/cmplist',
                target: 'RC',
                hidden: false,
            },
            {
                title: 'Account Option',
                link: '/user/optionac',
                target: 'TR-TD',
                hidden: false,
            },
            {
                title: 'Email Configer',
                link: '/user/optionemail',
                target: 'TR-TD',
                hidden: false,
            },
            {
                title: 'Invoice Configer',
                link: '/user/optioninv',
                target: 'TR-TD',
                hidden: false,
            },
            {
                title: 'Import Excel',
                link: '/user/importexcel',
                target: 'TR-TD',
                hidden: false,
            },
        ]
    },
    {
        title: 'Logout',
        icon: 'fa fa-sign-out-alt',
        link: '/auth/logout',
        target: 'TR-TD',
        hidden: false,
    },
];

function findRole() {
    var token = localStorage.getItem('tpsToken');
    var decode = jwt_decode(token);
    var UserType = decode.A3;

    if (UserType == "Admin")
        return true;
    else
        return false;
}