import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Branch, UserBrDtl } from '../../../master-management/master-management.model';
import { environment } from '../../../../environments/environment';
import { AppConfig } from '../../../app.config';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})

export class BranchService {

  constructor(
    private http: HttpClient,
  ) { }

  getBranchDropdownList() {
    return this.http.get<Branch[]>(environment.HOST_URL + AppConfig.RBRANCH);
  }

  getUserBranch() {
    var token = localStorage.getItem('tpsToken');
    var decode = jwt_decode(token);
    var UsRefNo = decode.A2;
    return this.http.get<UserBrDtl[]>(environment.HOST_URL + AppConfig.UserBrList.concat('/?id=').concat(UsRefNo));
  }

}
