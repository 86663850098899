import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';
import { InvoiceService } from '../invoice.service'
import { InvFilter } from '../InvModel'

import { SharedService } from '../../shared/service/shared.service';

@Component({
  selector: 'ngx-inv-filter',
  templateUrl: './inv-filter.component.html',
  styleUrls: ['./inv-filter.component.scss']
})
export class InvFilterComponent implements OnInit, OnDestroy {

  BranchData = [];
  sObj: InvFilter = new InvFilter();

  modalHeader: string;
  spinner: boolean;
  subscriptionManager = new Subscription();

  constructor(private sService: InvoiceService,
    private sSharedService: SharedService,
    private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.getBranchData();
  }

  closeModal() {
    this.activeModal.close();
  }

  getBranchData() {
    this.sService.getBranch().subscribe((data) => {
      this.BranchData = data['data'];
    }, (err) => { });
  }

  findInvData() {

    if (this.sObj.DtFrom != null) {
      let dt1: Date;
      dt1 = new Date(this.sObj.DtFrom);
      this.sObj.DtFrom = this.sSharedService.utcDate(dt1);
    }

    if (this.sObj.DtTo != null) {
      let dt1: Date;
      dt1 = new Date(this.sObj.DtTo);
      this.sObj.DtTo = this.sSharedService.utcDate(dt1);
    }

    if (this.sObj.NoFrom)
      this.sObj.NoFrom = Number(this.sObj.NoFrom);
    else
      this.sObj.NoFrom = 0;
    if (this.sObj.NoTo)
      this.sObj.NoTo = Number(this.sObj.NoTo);
    else
      this.sObj.NoTo = 0;

    this.activeModal.close(this.sObj);
  }

  ngOnDestroy() {
    if (this.subscriptionManager) {
      this.subscriptionManager.unsubscribe();
    }
  }
}
