import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';
import { StationDtl } from '../masterModel';
import { MastersService } from '../masters.service';
import { NbToastrService } from '@nebular/theme';
import { CommonService } from '../../shared/service/common.service';

@Component({
  selector: 'ngx-ct-create',
  templateUrl: './ct-create.component.html',
  styles: []
})
export class CtCreateComponent implements OnInit, OnDestroy {

  modalHeader: string;
  spinner: boolean;
  subscriptionManager = new Subscription();

  sObj: StationDtl = new StationDtl();
  OldName: string;
  StateData = [];

  constructor(
    private activeModal: NgbActiveModal,
    private sMasterService: MastersService,
    private sToastrService: NbToastrService,
    private sCommonService: CommonService

  ) { }

  ngOnInit() {
    this.getStateList();
    if (this.sObj.StationPRefNo) {
      this.OldName = this.sObj.Station;
    }
  }

  getStateList() {
    this.spinner = true;
    this.subscriptionManager.add(this.sCommonService.getState().subscribe((res) => {
      this.spinner = false;
      if (res == null) return;
      if (res['data'] == null) return;
      this.StateData = res['data'];
    }, (err) => {
      this.spinner = false;
    }));
  }



  closeModal() {
    this.activeModal.close();
  }

  saveStationData() {
    if (this.sObj.StationPRefNo) {
      this.updateStationObj();
    } else {
      this.addStationObj();
    }
  }

  addStationObj() {
    this.spinner = true;
    this.subscriptionManager.add(this.sMasterService.addStation(this.sObj).subscribe((res) => {
      this.activeModal.close(res);
      this.spinner = false;
    }, (err) => {
      this.activeModal.close();
      this.spinner = false;

    }));
  }


  updateStationObj() {

    this.spinner = true;
    this.subscriptionManager.add(this.sMasterService.updateStation(this.sObj).subscribe((res) => {
      if (res != null) {
        if (res['StatusCode'] == 501) {
          this.sObj.Station = this.OldName;
        }

      }
      this.activeModal.close(res);
      this.spinner = false;
    }, (err) => {
      this.spinner = false;
    }));
  }

  ngOnDestroy() {
    if (this.subscriptionManager) {
      this.subscriptionManager.unsubscribe();
    }
  }
}
