export class VhDtl {
    constructor(
        public VhPRefNo: number = 0,
        public VhNo: string = '',
        public VhCatName: string = '',
        public VhModel: string = '',
        public VhEngNo: string = '',
        public VhChsNo: string = '',
        public VhEmtyWt: number = 0,
        public VhPasWt: number = 0,
        public VhBlNo: string = '',
        public VhPurDt?: Date,
        public tVhPurDt: string = '',
        public VhRegDt?: Date,
        public tVhRegDt: string = '',
        public VhStdDslAvg: number = 0,
        public VhOnAtch: string = '',
        public VhOnrName: string = '',
        public VhPanNo: string = '',
        public VhOnrMobNo: string = '',
        public VhRCBook: string = '',
        public VhTdsForm: string = '',
        public VhDrvName: string = '',
        public VhDrvMobNo: string = '',
        public VhTnkChsNo: string = '',
        public VhTnkNo: string = '',
        public VhSize: string = '',
        public VhPasDt?: Date,
        public tVhPasDt: string = '',
        public VhSPDt?: Date,
        public tVhSPDt: string = '',
        public VhInsDt?: Date,
        public tVhInsDt: string = '',
        public VhRTaxDt?: Date,
        public tVhRTaxDt: string = '',
        public VhFitDt?: Date,
        public tVhFitDt: string = '',
        public VhNPDt?: Date,
        public tVhNPDt: string = '',
        public VhPUCDt?: Date,
        public tVhPUCDt: string = '',
        public VhCalDt?: Date,
        public tVhCalDt: string = '',
        public VhExplDt?: Date,
        public tVhExplDt: string = '',
        public VhHYearDt?: Date,
        public tVhHYearDt: string = '',
        public VhYearDt?: Date,
        public tVhYearDt: string = '',
        public VhHydDt?: Date,
        public tVhHydDt: string = '',
        public VhAuthDt?: Date,
        public tVhAuthDt: string = '',
        public VhOtherDt?: Date,
        public tVhOtherDt: string = '',
        public VhPurAmt: number = 0,
        public VhLoanAmt: number = 0,
        public VhInstAmt: number = 0,
        public VhHP: string = '',
        public VhLoanAcRefNo: number = 0,
        public VhPayAcRefNo: number = 0,
        public VhInstDt: number = 0,
        public tVhInstDt: string = '',
        public VhPayAcName: string = '',
        public VhLoanAcName: string = '',
    ) { }


}

export class AcmCombo {
    constructor(
        public AcPRefNo: number = 0,
        public AcName: string = '',
        public AcBankStat: string = '',
    ) { }
}

export class DocDtl {
    constructor(
        public DocPRefNo: number = 0,
        public DocGrpName: string = '',
        public DocName: string = '',
        public DocCmpCode: string = ''
    ) { }
}
export class DocFilter {
    constructor(
        public FrmDocSize: number = 0,
        public FrmDocType: string = '',
        public FrmDocBase: string = '',
        public FrmDocName: string = '',
        public FrmGrpName: string = '',
        public FrmGrpCode: string = ''
    ) { }
}

export class ScanDtl {
    constructor(
        public ScPRefNo: number = 0,
        public ScDocName: string = '',
        public ScGrpName: string = '',
        public ScGrpCode: string = ''
    ) { }
}