export let environment = {
  production: false,

  //AUTH_URL: 'https://localhost:44317/',
  AUTH_URL: 'http://api2.rectrans.in/',
  //AUTH_URL: 'http://api5.rectrans.in/',

  //HOST_URL: 'https://localhost:44317/',
  HOST_URL: 'http://api2.rectrans.in/',
  //HOST_URL: 'http://api5.rectrans.in/',
};
